import styles from './index.module.less'
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router-dom'
import { Image, Toast} from 'antd-mobile'
import { requestHttpHintWhenFail } from '../../../utils/request'
import cashierBg from '../../../images/blind-box/cashier-bg.jpg'
import tiXianMingXi from '../../../images/blind-box/tixian-mingxi.png'
import shouYiMingXi from '../../../images/blind-box/shouyi-mingxi.png'
import tiXian from '../../../images/blind-box/tixian.png'
import erweima from '../../../images/blind-box/erweima.png'

const PartnerPanel = forwardRef((props, ref) => {
    const cellAreaRef = useRef()
    const [cellAreaHeight, setCellAreaHeight] = useState(300)
    const[incomeData, setIncomeData] = useState({
        remainWithdraw: '-',
        todayIncome: '-',
        totalIncome: '-',
        totalWithdraw: '-'
    })
    const navigate = useNavigate();

    const refreshPartnerPanel = () => {
        requestHttpHintWhenFail('/blindbox/api/queryPromotionIncome').then(res => {
            setIncomeData(res.data)
        })
    }

    useImperativeHandle(ref, () => {
        return {
            refreshPartnerPanel: refreshPartnerPanel
        }
    })

    useEffect(() => {
        //动态计算切换标签以下的页面剩余高度
        if(cellAreaRef.current) {
            const rect = cellAreaRef.current.getBoundingClientRect()
            setCellAreaHeight(window.innerHeight - rect.top - 10)
        }
        refreshPartnerPanel()
    }, [])

    const toWithdrawalPage = () => {
        Toast.show("开发中，暂时联系人工处理")
    }

    const toIncomeDetailPage = () => {
        navigate('/h5/blind-box/income-detail');
    }

    const toWithdrawDetailPage = () => {
        Toast.show("开发中，敬请期待")
    }

    const toPagePromotion = () => {
        navigate('/h5/blind-box/promotion');
    }

    return (
        <div className={styles['partner-manage']}>
            <Image className={styles['partner-manage-bg']} fit="fill" src={cashierBg} />
            <div className={styles['withdrawal-amount']} onClick={toWithdrawalPage}>
                <div className={styles['withdrawal-amount-title']}>可提现金额</div>
                <div className={styles['withdrawal-amount-value']}>¥{incomeData?.remainWithdraw}</div>
            </div>
            <div className={styles['amount-item-list']}>
                <div className={styles['amount-item']} onClick={toIncomeDetailPage}>
                    <div className={styles['amout-item-title']}>今日收益</div>
                    <div className={styles['amout-item-value']}>{incomeData?.todayIncome}</div>
                </div>
                <div className={`${styles['amount-item']} ${styles['divide-line']}`} onClick={toIncomeDetailPage}>
                    <div className={styles['amout-item-title']}>累计收益</div>
                    <div className={styles['amout-item-value']}>{incomeData?.totalIncome}</div>
                </div>
                <div className={styles['amount-item']} onClick={toWithdrawDetailPage}>
                    <div className={styles['amout-item-title']}>累计提现</div>
                    <div className={styles['amout-item-value']}>{incomeData?.totalWithdraw}</div>
                </div>
            </div>
            <div ref={cellAreaRef} className={styles['cell-area']} style={{height: `${cellAreaHeight}px`}}>
                <div className={styles['cell-item-list']}>
                    <div className={styles['cell-item']} onClick={toPagePromotion}>
                        <Image className={styles['cell-item-icon']} src={erweima} />
                        <div className={styles['cell-item-desc']}>推广物料</div>
                    </div>
                    <div className={styles['cell-item']} onClick={toIncomeDetailPage}>
                        <Image className={`${styles['cell-item-icon']} ${styles['shouyi-mingxi-icon']}`} src={shouYiMingXi} />
                        <div className={styles['cell-item-desc']}>收益明细</div>
                    </div>
                    <div className={styles['cell-item']} onClick={toWithdrawalPage}>
                        <Image className={styles['cell-item-icon']} src={tiXian} />
                        <div className={styles['cell-item-desc']}>立即提现</div>
                    </div>
                    <div className={styles['cell-item']} onClick={toWithdrawDetailPage}>
                        <Image className={`${styles['cell-item-icon']} ${styles['tixian-mingxi-icon']}`} src={tiXianMingXi} />
                        <div className={styles['cell-item-desc']}>提现记录</div>
                    </div>
                </div>
            </div>
        </div>
    )
}) 

export default PartnerPanel;