import styles from './index.module.less'
import { Mask, TextArea, Button } from 'antd-mobile'
import UserInfoArea from '../user-info-area'

export default function BottleShowPopup({visible, userInfo={}, bottleRecord={}, replyValue, replyButtonLoading,
    onClickReplyButton = () => {}, 
    onClickCancelButton = () => {},
    onClickReportButton = () => {},
    onReplyValueChange = () => {},
    onMaskClick = () => {}
}) {

    const toMainPage = () => {
        if(window.__wxjs_environment === 'miniprogram') {
            window.wx.miniProgram.navigateTo({
                url: `/pages/main-page/main-page?userId=${userInfo.userId}`
            })
        }
    }

    return (
        <Mask className={styles.bottleShowPopupMask} visible={visible} onMaskClick={onMaskClick}>
            <div className={styles.bottleShowPopup}>
                <div className={styles.userInfoLine}>
                    <UserInfoArea userInfo={userInfo} onClick={toMainPage}/>
                    <div className={styles.reportButton} onClick={onClickReportButton}>举报</div>
                </div>
                <div className={styles.textContent}>{bottleRecord.content?.text}</div>
                <div className={styles.textArea}>
                    <TextArea placeholder='回应并和TA聊天' 
                        value={replyValue} rows={3} maxLength={1024} onChange={onReplyValueChange}/>
                </div>
                <div className={styles.buttonList}>
                    <Button className={styles.cancelButton} onClick={onClickCancelButton}>取消</Button>
                    <Button className={styles.replyButton} onClick={onClickReplyButton} loading={replyButtonLoading}>回应</Button>
                </div>
            </div>
        </Mask>
    )
}
