import styles from './index.module.less'
import { useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import { NavBar, Toast } from 'antd-mobile'
import { requestHttpHintWhenFail } from '../../../utils/request'

export default function Promotion() {
    const navigate = useNavigate()
    const pageBodyRef = useRef()
    const [pageBodyHeight, setPageBodyHeight] = useState(300)
    const [promotion, setPromotion] = useState({})
    const goBack = () => {
        navigate(-1)
    }
    useEffect(() => {
        //动态计算切换标签以下的页面剩余高度
        if(pageBodyRef.current) {
            const rect = pageBodyRef.current.getBoundingClientRect()
            setPageBodyHeight(window.innerHeight - rect.top)
        }
        requestHttpHintWhenFail('/blindbox/api/getPromotionInfo').then(res => {
            if(res.data) {
                setPromotion(res.data)
            }
        })
    }, [])
    const copyInnerRedirectUrl = () => {
        navigator.clipboard.writeText(promotion?.innerRedirectUrl)
        Toast.show('链接已复制')
    }
    return (
        <div>
            <NavBar onBack={goBack}>推广物料</NavBar>
            <div ref={pageBodyRef} className={styles.pageBody} style={{height: `${pageBodyHeight}px`}}>
                <div className={styles['promotion-page']}>
                    {window.__wxjs_environment !== 'miniprogram' && <>
                        <div className={styles['title']} style={{ paddingTop: 0 }}>一键分享</div>
                        <div className={styles['desc']}>在本页面或首页点击右上角三个点，一键转发给朋友或分享到朋友圈，转发的内容中会包含您的身份信息，用户进入脱单盲盒下单后产生的佣金会计入您的账户。</div>
                    </>}
                    <div className={styles['title']}>专属内部二维码（仅支持微信扫码）</div>
                    <div className={styles['desc']}>长按二维码可保存到手机。该二维码含有您的身份信息，用户通过微信扫描该二维码进入脱单盲盒下单后产生的佣金会计入您的账户。</div>
                    {!promotion?.innerQrCodeUrl ? (
                        <div className={styles['empty-image-style']}>二维码制作中</div>
                    ) : (
                        <img className={styles['qr-code-image']} src={promotion?.innerQrCodeUrl} alt="二维码" />
                    )}

                    {/* 
                    <div className={styles['title']}>专属内部链接（仅支持微信内部访问）</div>
                    <div className={styles['desc']}>该链接含有您的身份信息，用户点击该链接即可从朋友圈、群聊、单聊跳转到脱单盲盒，用户下单后产生的佣金会计入您的账户。</div>
                    {!promotion?.innerRedirectUrl ? (
                        <div className={styles['empty-url-style']}>链接制作中</div>
                    ) : (
                        <div className={styles['redirect-wrapper']}>
                        <div className={styles['redirect-url-style']}>{promotion?.innerRedirectUrl}</div>
                        <div className={styles['copy-url-button']} onClick={copyInnerRedirectUrl}>
                            点此复制链接
                        </div>
                        </div>
                    )}
                    <div className={styles['title']}>专属外部二维码（支持外部浏览器扫码）</div>
                    <div className={styles['desc']}>长按二维码可保存到手机。该二维码含有您的身份信息，用户通过微信或外部浏览器扫描该二维码进入脱单盲盒下单后产生的佣金会计入您的账户。</div>
                    {!qrCodeUrl ? (
                        <div className={styles['empty-image-style']}>二维码制作中</div>
                    ) : (
                        <img className={styles['qr-code-image']} src={qrCodeUrl} alt="二维码" />
                    )}

                    <div className={styles['title']}>专属外部链接（支持微信外部访问）</div>
                    <div className={styles['desc']}>该链接含有您的身份信息，用户点击该链接即可从其他平台（如抖音、微博、知乎、小红书、QQ等）跳转到脱单盲盒，用户下单后产生的佣金会计入您的账户。</div>
                    {!redirectUrl ? (
                        <div className={styles['empty-url-style']}>链接制作中</div>
                    ) : (
                        <div className={styles['redirect-wrapper']}>
                        <div className={styles['redirect-url-style']}>{redirectUrl}</div>
                        <div className={styles['copy-url-button']} onClick={onTapCopyUrlButton}>
                            点此复制链接
                        </div>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    )
}
