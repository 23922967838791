import styles from './index.module.less';
import { useNavigate } from 'react-router-dom';

function About() {
  document.documentElement.setAttribute('data-prefers-color-scheme', 'light')
  const navigate = useNavigate();
  return (
    <>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/icp-beian')}>备案首页</div>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/bottle-recharge')}>漂流瓶充值-浅色</div>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/bottle-recharge?theme=dark')}>漂流瓶充值-深色</div>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/circle-recharge')}>表白墙充值-浅色</div>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/circle-recharge?theme=dark')}>表白墙充值-深色</div>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/bottle-homepage')}>漂流瓶-主页</div>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/bottle-message')}>漂流瓶-消息页</div>
        <div className={styles.linkStyle} onClick={() => navigate('/h5/blind-box')}>脱单盲盒-主页</div>
    </>
  );
}
  
export default About;