import styles from './index.module.less'
import { Image } from 'antd-mobile'
import UserGenderIcon from '../user-gender-icon'

export default function UserInfoArea({className, userInfo, onClick = () => {}}) {
    return userInfo && (
        <div className={`${className} ${styles.userInfoArea}`} onClick={onClick}>
            <Image className={styles.avatar} src={userInfo.avatar}/>
            <div className={styles.textArea}>
                <div className={styles.firstLine}>
                    <div className={styles.userNick}>{userInfo.userNick}</div>
                    <UserGenderIcon gender={userInfo.gender}/>
                </div>
                {userInfo.descList && <div className={styles.descList}>
                    {userInfo.descList.map(desc => (<div key={desc} className={styles.descItem}>{desc}</div>))}
                </div>}
            </div>
        </div>
        
    )
}