import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import qs from "query-string";

/**
 * 适用场景：公众号h5分享场景下，需要保持分享链接的域名和授权域名一致
 * 但是分享的域名又需要获取微信登录态，所以只能是微信的域名，这是就需要跳转一下
 */
export default function CommonRedirect() {
    const {search} = useLocation()
    //这里拿到的targetUrl已经是url解码后的值
    const {targetUrl} = qs.parse(search)
    useEffect(() => {
        if(targetUrl) {
            window.location.replace(targetUrl);
        }
    }, [])
}