import {Image} from 'antd-mobile';
import styles from './index.module.less'
import goldCoinPng from '../../images/gold-coin.png'


export default function RechargeCard({theme='light', cardInfo = {}, onClick = (cardInfo) => {}}) {
    return (
        <div className={`${styles[theme]} ${styles.rechargeCard} ${cardInfo.checked ? styles.rechargeCardActive : ''}`} onClick={() => onClick(cardInfo)}>
            <div className={styles.rechargeCardTopLine}>
                <div className={styles.rechargeCardCoinCnt}>{cardInfo.coinCnt}</div>
                <Image className={styles.rechargeCardCoinIcon} src={goldCoinPng}/>
            </div>
            <div className={styles.rechargeCardBottomLine}>{cardInfo.price}元</div>
        </div>
    )
}