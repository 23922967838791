import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import qs from "query-string";

/**
 * 获取到微信授权code后会将code拼接到targetUrl末尾并重定向到targetUrl
 * 适用场景：突破微信公众号只能配置两个授权域名的限制，使为配置授权的域名也能正常获取到微信登录态
 */
export default function AuthRedirect() {
    const {search} = useLocation()
    //这里拿到的targetUrl已经是url解码后的值
    const {targetUrl, code} = qs.parse(search)
    useEffect(() => {
        if(targetUrl) {
            let url = targetUrl
            if(url.indexOf('?') >=0) {
                url = `${url}&code=${code}`
            } else {
                url = `${url}?code=${code}`
            }
            window.location.replace(url);
        }
    }, [])
}