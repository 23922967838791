import axios from 'axios';
import { Toast } from 'antd-mobile';

export default function requestHttp(api, data, method="GET", headers={}) {
    //去除对象中的空属性
    if(data) {
        Object.keys(data).forEach(key => {
        if(data[key] == null) {
            delete data[key]
        }
        })
    }
    let contentType = 'application/json';
    if(!headers['content-type']) {
        headers['content-type'] = contentType
    }
    if(method && method.toLowerCase() === 'get') {
        return new Promise((resolve, reject) => {
            axios.get(api, {params: data, headers: headers, withCredentials: true})
            .then(response => {
                processResponse(response, resolve, reject)
            }).catch(err => reject(err))
        })
    } else {
        return new Promise((resolve, reject) => {
            axios.post(api, data, {headers: headers, withCredentials: true})
            .then(response => {
                processResponse(response, resolve, reject)
            }).catch(err => reject(err))
        })
    }
}

export function requestHttpHintWhenFail(api, data, method="GET", headers={}) {
    return new Promise((resolve, reject) => {
        requestHttp(api, data, method, headers).then(res => {
            resolve(res)
        }).catch(errorMsg => {
            Toast.show({
                icon: 'fail',
                content: errorMsg ? errorMsg : '系统繁忙',
                duration: 2000
            })
            reject(errorMsg)
        })
    })
}

function processResponse(response, resolve, reject) {
    if(response.status === 200) {
        if(response?.data?.success) {
            resolve(response.data)
        } else {
            reject(response?.data?.errorMsg)
        }
    } else {
        reject('http status error')
    }
}