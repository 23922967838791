import styles from './index.module.less';
import { useNavigate } from 'react-router-dom';
import { Empty } from 'antd-mobile'
import BottleTabbar from '../../components/bottle-tabbar';
import homeImage from '../../images/home.png';
import homeActiveImage from '../../images/home-active.png';
import messageImage from '../../images/message.png';
import messageActiveImage from '../../images/message-active2.png'

const icons = [
    {
        image: homeImage,
        activeImage: homeActiveImage,
        name: '漂流瓶'
    },{
        image: messageImage,
        activeImage: messageActiveImage,
        name: '消息'
    }
]

export default function BottleMessage() {
    const navigate = useNavigate();
    const onClickIcon = (index) => {
        if(index === 0) {
            //跳转到bottleMessage
            navigate('/h5/bottle-homepage')
        }
    }
    return (
        <div>
            <div className={styles.messagePage}>
                <Empty className={styles.emptyStyle} description='暂无消息' />
            </div>
            <BottleTabbar activeIndex={1} icons={icons} onClickIcon={onClickIcon}/>
        </div>
    )
}