import styles from './index.module.less'
import { Image, Button } from 'antd-mobile'
import maleImage from '../../images/male.png'
import femaleImage from '../../images/female.png'

export default function UserInfoCard({theme = "light", userNick, avatar, userId, gender, buttonName, onClickButton = () => {}}) {
    return (
        <div className={`${styles[theme]} ${styles.userInfoCard}`}>
            <div className={styles.leftPart}>
                {avatar && <Image className={styles.avatar} src={avatar} />}
                <div className={styles.userNickAndIdBlock}>
                    <div className={styles.userNickLine}>
                        <div className={styles.userNick}>{userNick}</div>
                        {gender != null && <Image className={styles.genderIcon} src={gender === 1 ? maleImage : femaleImage}/>}
                    </div>
                    <div className={styles.userIdLine}>用户ID: {userId}</div>
                </div>
            </div>
            <div className={styles.rightPart}>
                <Button className={styles.buttonStyle}
                        color='primary'
                        block
                        onClick={onClickButton}
                        size='middle'>{buttonName}</Button>
            </div>
        </div>
    )
}