import styles from './index.module.less';
import {Image, Popup} from 'antd-mobile';

export default function ImagePopup({className, visible = false, title, subTitle, 
    showCloseButton, image, buttonName, height=40, imageWidth, imageHeight, 
    onClose=()=>{},onMaskClick=()=>{}, onButtonClick=()=>{}}) {
    return (
        <Popup
            className={className}
            visible={visible}
            showCloseButton={showCloseButton}
            onClose={onClose}
            onMaskClick={onMaskClick}
            bodyStyle={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: `${height}vh` }}>
            <div className={styles.popupBody}>
                {title && <div className={styles.title}>{title}</div>}
                {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
                {image && <Image className={styles.image} width={imageWidth} height={imageHeight} src={image}/>}
                {buttonName && <div className={styles.button}>{buttonName}</div>}
            </div>
        </Popup>
    )
}
