import styles from './index.module.less';
import { Button, CenterPopup, Input } from 'antd-mobile';
import { useState } from 'react';


export default function InputPopup({theme = "light", visible = false, title, subTitle, placeholder, 
    type = 'number', buttonLoading = false, buttonName = "确认", onClickButton = (inputValue) => {},
    onMaskClick = () => {}}) {

    const [inputValue, setInputValue] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(true)
    return (
        <CenterPopup
            visible={visible}
            onMaskClick={onMaskClick}>
            <div className={styles.inputPopupBody}>
                {title && <div className={styles.inputPopupTitle}>{title}</div>}
                {subTitle && <div className={styles.inputPopupSubTitle}>{subTitle}</div>}
                <Input
                    className={styles.inputBox}
                    placeholder={placeholder}
                    clearable
                    value={inputValue}
                    onChange={val => {
                        setInputValue(val)
                        if(val && val.length > 0) {
                            setButtonDisabled(false)
                        } else {
                            setButtonDisabled(true)
                        }
                    }}
                    style={{ '--text-align': 'center' }}
                    type={type}
                />
                <Button className={styles.submitButton}
                    loading={buttonLoading}
                    disabled={buttonDisabled}
                    color='primary'
                    block
                    onClick={() => onClickButton(inputValue)}
                    size='middle'>{buttonName}</Button>
            </div>  
        </CenterPopup>
    )
}