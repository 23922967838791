
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import qs from "query-string";

/**
 * 将targetUrl包含到微信授权链接中，并重定向到微信授权，可将微信授权链接缩短
 * 适用场景：生成更简单的二维码
 */
export default function WxAuth() {
    const {search} = useLocation()
    //这里拿到的targetUrl已经是url解码后的值
    const {targetUrl} = qs.parse(search)
    useEffect(() => {
        if(targetUrl) {
            let wxAuthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7aeb3fcd3ee2d222&redirect_uri=${encodeURIComponent(targetUrl)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
            window.location.replace(wxAuthUrl);
        }
    }, [])
}