import { Toast } from "antd-mobile";

export function launchWxPayByJSBridge(prepayResponse, callback = () => {}) {
    let environment = window.__wxjs_environment
    if(environment === 'miniprogram') {
        //说明在小程序内部webview中，此时应该跳转小程序通用支付页面
        window.wx.miniProgram.navigateTo({
            url: `/pages/common-pay/common-pay?param=${encodeURIComponent(JSON.stringify(prepayResponse))}`,
            fail: (err) => {
                console.log(err)
            }
        })
    } else {
        //发起jsapi支付（仅支持微信内部浏览器发起，小程序内webview发起无效）
        if (typeof WeixinJSBridge === "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', () => onBridgeReady(prepayResponse, callback), false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', () => onBridgeReady(prepayResponse, callback));
                document.attachEvent('onWeixinJSBridgeReady', () => onBridgeReady(prepayResponse, callback));
            }
        } else {
            onBridgeReady(prepayResponse, callback);
        }
    }
    return environment
}

const onBridgeReady = (data, callback = () => {}) => {
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": data.appId,     //公众号ID，由商户传入     
        "timeStamp": data.timeStamp,     //时间戳，自1970年以来的秒数     
        "nonceStr": data.nonceStr,      //随机串     
        "package": data.packageVal,
        "signType": data.signType,     //微信签名方式：     
        "paySign": data.paySign //微信签名 
    },
    (res) => {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            //支付成功后回调
            callback()
        } else if(res.err_msg === "get_brand_wcpay_request:cancel") {
            //支付取消, do nothing

        } else {
          Toast.show({
            icon: 'fail',
            content: res.err_msg,
            duration: 2000
          })
        }
    })
}