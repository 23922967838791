import { useState, useEffect } from 'react'
import { NoticeBar } from 'antd-mobile'

export default function MemoryNoticeBar({ sceneCode, noticeConfig={}, delay=2000}) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        if(sceneCode && noticeConfig && noticeConfig.content) {
            //查询本地缓存
            const version = localStorage.getItem(sceneCode)
            if(!version || version != noticeConfig.version) {
                setShow(true)
            }
        }
    }, [])
    const onClose = () => {
        localStorage.setItem(sceneCode, noticeConfig.version)
    }
    return ( show ? 
        <NoticeBar content={noticeConfig.content} color='info' closeable onClose={onClose} delay={delay}/>
        : <></>
    )
}
