import styles from './index.module.less'
import { useState, useEffect, useRef } from 'react'
import { Button, InfiniteScroll, List, Tabs, ErrorBlock, Swiper } from 'antd-mobile';
import { requestHttpHintWhenFail } from '../../../utils/request';
import BottleShowCard from '../components/bottle-show-card';

const tabItems = [
    { key: 'throw', title: '我扔出的' },
    { key: 'salvage', title: '我捞到的' }
]
export default function MyBottle() {
    const [bottleRecordList, setBottleRecordList] = useState([])
    const [bottleRecordHasMore, setBottleRecordHasMore] = useState(true)
    const [bottlePageIndex, setBottlePageIndex] = useState(1)
    const [salvageRecordList, setSalvageRecordList] = useState([])
    const [salvageRecordHasMore, setSalvageRecordHasMore] = useState(true)
    const [salvagePageIndex, setSalvagePageIndex] = useState(1)
    const [activeIndex, setActiveIndex] = useState(0)
    const [scrollYHeight, setScrollYHeight] = useState(300)
    const tabRef = useRef()
    const swiperRef = useRef()

    useEffect(() => {
        document.title = '我的纸条'
        //动态计算切换标签以下的页面剩余高度
        if(tabRef.current) {
            const rect = tabRef.current.getBoundingClientRect()
            setScrollYHeight(window.innerHeight - rect.bottom)
        }
    }, [])

    const pagingQueryThrowRecords = (index) => {
        let pageIndex = Number.isFinite(index) ? index : bottlePageIndex
        return new Promise((resolve, reject) => {
            if(!bottleRecordHasMore) {
                resolve()
                return
            }
            requestHttpHintWhenFail('/circle/api/pagingQueryThrowRecords', {
                pageIndex: pageIndex,
                pageSize: 20
            }).then(res => {
                setBottlePageIndex(bottlePageIndex + 1)
                if(res.data && res.data.length > 0) {
                    setBottleRecordList([...bottleRecordList, ...res.data])
                } else {
                    setBottleRecordHasMore(false)
                }
                resolve()
            }).catch(err => {
                reject()
            })
        })
    }

    const pagingQuerySalvageRecords = (index) => {
        let pageIndex = Number.isFinite(index) ? index : salvagePageIndex
        return new Promise((resolve, reject) => {
            if(!salvageRecordHasMore) {
                resolve()
                return
            }
            requestHttpHintWhenFail('/circle/api/pagingQuerySalvageRecords', {
                pageIndex: pageIndex,
                pageSize: 20
            }).then(res => {
                setSalvagePageIndex(salvagePageIndex + 1)
                if(res.data  && res.data.length > 0) {
                    setSalvageRecordList([...salvageRecordList, ...res.data])
                } else {
                    setSalvageRecordHasMore(false)
                }
                resolve()
            }).catch(err => {
                reject()
            })
        })
        
    }

    const onTabChange = (key) => {
        const index = tabItems.findIndex(item => item.key === key)
        setActiveIndex(index)
        swiperRef.current?.swipeTo(index)
    }

    const onIndexChange = (index) => {
        setActiveIndex(index)
    }

    const deleteBottleRecord = (bottleRecord) => {
        let newBottleRecordList = bottleRecordList.filter(record => record.id !== bottleRecord.id)
        setBottleRecordList(newBottleRecordList)
        requestHttpHintWhenFail('/circle/api/hideBottleRecord', {
            bottleId: bottleRecord.id
        }, 'POST')
    }

    const deleteSalvageRecord = (bottleRecord) => {
        let salvageRecord = salvageRecordList.find(record => record.userBottleRecord.id === bottleRecord.id)
        let recordId = salvageRecord.userSalvageRecord.id
        let newSalvageRecordList = salvageRecordList.filter(record => record.userBottleRecord.id !== bottleRecord.id)
        setSalvageRecordList(newSalvageRecordList)
        requestHttpHintWhenFail('/circle/api/hideSalvageRecord', {
            recordId: recordId
        }, 'POST')
    }

    return (
        <div className={styles.myNotePage}>
            <div ref={tabRef}>
                <Tabs activeKey={tabItems[activeIndex].key} onChange={onTabChange}>
                    {tabItems.map(tabItem => <Tabs.Tab title={tabItem.title} key={tabItem.key}/>)}
                </Tabs>
            </div>
            <Swiper direction='horizontal'
                indicator={() => null}
                ref={swiperRef}
                defaultIndex={activeIndex}
                onIndexChange={onIndexChange}>
                <Swiper.Item>
                    <div className={styles.scrollY} style={{height: `${scrollYHeight}px`}}>
                        <MyThrowRecords bottleRecordList={bottleRecordList} 
                            loadMore={pagingQueryThrowRecords} 
                            hasMore={bottleRecordHasMore}
                            onDelete={deleteBottleRecord}/>
                    </div>
                </Swiper.Item>
                <Swiper.Item>
                    <div className={styles.scrollY} style={{height: `${scrollYHeight}px`}}>
                        <MySalvageRecords salvageRecordList={salvageRecordList} 
                        loadMore={pagingQuerySalvageRecords} 
                        hasMore={salvageRecordHasMore}
                        onDelete={deleteSalvageRecord}/>
                    </div>
                </Swiper.Item>
            </Swiper>
        </div>
    )
}

export function MyThrowRecords({ bottleRecordList, loadMore, hasMore = false, onDelete = (bottleRecord) => {}}) {
    if(hasMore || (bottleRecordList && bottleRecordList.length > 0)) {
        return (<>
            {bottleRecordList && 
                <List>
                    {bottleRecordList.map(record => 
                        <List.Item key={record?.id}>
                            <BottleShowCard bottleRecord={record} onClickDeleteButton={onDelete}/>
                        </List.Item>)}
                </List>
            }
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} children={<div>没有更多了</div>}/>
        </>)
    } else {
        return (
            <ErrorBlock 
                status='empty' 
                title={<div>暂无数据</div>}
                description={<div>去扔一个纸条吧</div>}
            />
        )
    }
}

export function MySalvageRecords({ salvageRecordList, loadMore, hasMore = false, onDelete = (bottleRecord) => {}}) {
    if(hasMore || (salvageRecordList && salvageRecordList.length > 0)) {
        return (<>
            {salvageRecordList && 
                <List>
                    {salvageRecordList.map(record => 
                        <List.Item key={record?.userSalvageRecord?.id}>
                            <BottleShowCard bottleRecord={record.userBottleRecord} 
                                userInfo={record.userInfo} 
                                salvageRecord={record.userSalvageRecord}
                                onClickDeleteButton={onDelete}/>
                        </List.Item>)}
                </List>
            }
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} children={<div>没有更多了</div>}/>
        </>)
    } else {
        return (
            <ErrorBlock 
                status='empty' 
                title={<div>暂无数据</div>}
                description={<div>去捞一个纸条吧</div>}
            />
        )
    }
}