import styles from './index.module.less';
import { useLocation } from "react-router-dom";
import { Tabs, Swiper, Image, Button, Toast, Popup, Divider, TextArea, ImageUploader, Input, 
    Switch, PullToRefresh, InfiniteScroll, List, Ellipsis} from 'antd-mobile'
import qs from "query-string";
import { useState, useEffect, useImperativeHandle, useRef, forwardRef} from 'react';
import { Area, Dialog } from 'react-vant';
import { areaList } from '@vant/area-data'
import { CloseCircleOutline, RightOutline } from 'antd-mobile-icons'
import { useDispatch, useSelector } from 'react-redux';
import Compressor from 'compressorjs';
import { KeepAlive } from 'react-activation';
import { login, blindBoxSwitchTab, switchMyNoteTab, setAppConfig} from '../../../redux/globalSlice';
import { launchWxPayByJSBridge } from '../../../utils/comment';
import MemoryNoticeBar from '../../../components/memory-notice-bar';
import ImagePopup from '../../../components/image-popup';
import AgreementPopup from '../agreement-popup';
import PartnerPanel from '../partner-panel';
import requestHttp from "../../../utils/request";
import JoinPartner from '../join-partner';
import PopupActionSheet from '../../../components/popup-action-sheet';
import posterImage2 from '../../../images/blind-box/poster2.jpg';
import posterImage3 from '../../../images/blind-box/poster3.jpg';
import posterImage4 from '../../../images/blind-box/poster4.jpg';
import posterImage5 from '../../../images/blind-box/poster5.jpg';
import heartGif from '../../../images/blind-box/heart.gif';
import locationIcon from '../../../images/blind-box/location.png';
import manBoxImage from '../../../images/blind-box/man-box.png';
import womanBoxImage from '../../../images/blind-box/woman-box.png';
import tanmoWallImage from '../../../images/blind-box/tanmo-wall.jpg';
import matchChatImage from '../../../images/blind-box/match-chat.jpg';
import emptyImage from '../../../images/blind-box/empty.png';
import kefuWechatImage from '../../../images/blind-box/kefu-wechat.jpg';

const swiperItems = [
    {
        code: 'poster4',
        image: posterImage4,
    },
    {
        code: 'poster5',
        image: posterImage5,
    },
    {
        code: 'poster2',
        image: posterImage2,
    },
    {
        code: 'poster3',
        image: posterImage3,
    }
]

const frontVersion = '1.1.1'
let skuInfoMap = {}
const defaultCityName = '不限城市'

export default function BlindBox(props) {
    const location = useLocation()
    const search = location.search
    const {appName, sessionKey, code, sourceUserId, configVersion} = qs.parse(search)
    const dispatch = useDispatch()
    const tabActiveKey = useSelector((state) => state.global.blindBoxState.tabActiveKey)
    const userInfo = useSelector((state) => state.global.userInfo)
    const myNotePageRef = useRef()
    const auditCenterRef = useRef()
    const partnerPanelRef = useRef()
    const [showIphonePopup, setShowIphonePopup] = useState(false)
    const [showJoinPartnerPage, setShowJoinPartnerPage] = useState(false)

    const blindBoxlogin = () => {
        if(userInfo?.userId) {
            return
        }
        // 登录（后端有sessionKey的情况下优先走sessionKey登录）
        requestHttp('/blindbox/api/login', {
            appName: appName,
            sessionKey: sessionKey,
            loginCode: code,
            sourceUserId: sourceUserId
        }, "POST").then((res) => {
            if(res.data) {
                //设置全局变量
                dispatch(login({
                    userInfo: res.data,
                    sessionKey: res.data.sessionKey,
                    sourceUserId: sourceUserId
                }))
                //登录进行wxconfig设置
                initWxConfig(res.data)
            }
            //登录后查询skuInfo
            querySkuInfo()
        }).catch((errorMsg) => {
            Toast.show({icon: 'fail', content: '微信登录失败，请重试', duration: 2000})
        })
        //查询appConfig
        requestHttp('/blindbox/api/getAppConfig', {
            appName: appName,
            version: configVersion ? configVersion : '1.0.0'
        }).then(res => {
            dispatch(setAppConfig({
                appConfig: res.data
            }))
            setShowJoinPartnerPage(!!res.data.showJoinPartnerPage)
            //如果是苹果用户在小程序内打开时，弹出公众号弹窗，引导到公众号入口，因为苹果用户不能在小程序内支付
            if(window.__wxjs_environment === 'miniprogram'
                && /iPhone|Mac|iPad|iPod/.test(window.navigator.userAgent)) {
                if(res.data.applePopupBlock) {
                    setShowIphonePopup(true)
                }
            }
        }).catch((errorMsg) => {
            Toast.show({icon: 'fail', content: '配置获取失败', duration: 2000})
        })
    }

    const querySkuInfo = () => {
        requestHttp('/blindbox/api/querySkuInfo').then(res => {
            skuInfoMap = res.data
        })
    }

    const initWxConfig = (tmpUserInfo) => {
        //获取当前页面路径
        let url = window.location.href
        requestHttp('/blindbox/api/getJsapiSignData', {
            appName: appName,
            url: url
        }).then(res => {
            let jsapiSignData = res.data
            window.wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: jsapiSignData.appId, // 必填，公众号的唯一标识
                timestamp: jsapiSignData.timestamp, // 必填，生成签名的时间戳
                nonceStr: jsapiSignData.nonceStr, // 必填，生成签名的随机串
                signature: jsapiSignData.signature,// 必填，签名
                jsApiList: ['chooseImage', 'updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
                openTagList: ['wx-open-launch-weapp']
            })
            window.wx.ready(() => {
                //分享给朋友
                window.wx.updateAppMessageShareData({ 
                    title: '脱单盲盒', // 分享标题
                    desc: '快来抽个对象吧～', // 分享描述
                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    link: `https://www.wittyai.cn/h5/wx-auth?targetUrl=https%3A%2F%2Fwww.wittyai.cn%2Fh5%2Fblind-box%3FappName%3Dtm_star_pa%26sourceUserId%3D${tmpUserInfo?.userId}`, 
                    imgUrl: 'https://wittyai.cn/common-files/blind-box-logo.jpeg', // 分享图标
                    success: function () {
                        console.log('updateAppMessageShareData success')
                    },
                    fail: function(err) {
                        console.log('updateAppMessageShareData fail', err)
                    }
                })
                //分享到朋友圈
                window.wx.updateTimelineShareData({ 
                    title: '脱单盲盒', // 分享标题
                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    link: `https://www.wittyai.cn/h5/wx-auth?targetUrl=https%3A%2F%2Fwww.wittyai.cn%2Fh5%2Fblind-box%3FappName%3Dtm_star_pa%26sourceUserId%3D${tmpUserInfo?.userId}`, 
                    imgUrl: 'https://wittyai.cn/common-files/blind-box-logo.jpeg', // 分享图标
                    success: function () {
                        console.log('updateTimelineShareData success')
                    },
                    fail: function(err) {
                        console.log('updateTimelineShareData fail', err)
                    }
                })
            })
        })
        
    }

    const onTabChange = (key) => {
        dispatch(blindBoxSwitchTab({
            tabActiveKey: key
        }))
    }

    const onPullToRefresh = () => {
        //下拉刷新时触发纸条页面刷新接口
        if(tabActiveKey === 'my-note' && myNotePageRef && myNotePageRef.current) {
            return myNotePageRef.current.triggerRefreshMyNote()
        }
        if(tabActiveKey === 'audit-center' && auditCenterRef && auditCenterRef.current) {
            return auditCenterRef.current.triggerRefreshMyNote()
        }
        if(tabActiveKey === 'partner-panel' && partnerPanelRef && partnerPanelRef.current) {
            return partnerPanelRef.current.refreshPartnerPanel()
        }
    }
    
    useEffect(() => {
        document.title = '脱单盲盒';
        // 创建一个 meta 标签
        const metaTag = document.createElement('meta');
        metaTag.name = 'viewport';
        // 禁止页面缩放（ios下输入操作会导致页面放大影响输入体验）
        metaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
        // 将 meta 标签添加到 head 中
        document.getElementsByTagName('head')[0].appendChild(metaTag);
        blindBoxlogin()
        // 组件卸载时移除 meta 标签，恢复缩放
        return () => {
            document.getElementsByTagName('head')[0].removeChild(metaTag);
        };
    }, [])

    useEffect(() => {
        if(userInfo?.userId == null) {
            return
        }
        //每次页面路径更改时需要重新触发初始化
        initWxConfig(userInfo)
    }, [location])

    return (
        <PullToRefresh
            className={styles.pullToRefresh}
            disabled={tabActiveKey === 'blind-box'}
            onRefresh={onPullToRefresh}>
            <div className={styles.blindBox}>
                <Tabs className={styles.tabsStyle} activeKey={tabActiveKey} defaultActiveKey={tabActiveKey} onChange={onTabChange}>
                    <Tabs.Tab key='blind-box' title='脱单盲盒' >
                        <BlindBoxPage />
                    </Tabs.Tab>
                    <Tabs.Tab key='my-note' title='我的纸条' >
                        <MyNotePage ref={myNotePageRef}/>
                    </Tabs.Tab>
                    {!userInfo?.partner && showJoinPartnerPage &&
                        <Tabs.Tab key='join-partner' title='创业合伙人' >
                            <JoinPartner />
                        </Tabs.Tab>
                    }
                    {userInfo?.auditor &&
                        <Tabs.Tab key='audit-center' title='审核中心' >
                            <AuditCenter ref={auditCenterRef}/>
                        </Tabs.Tab>
                    }
                    {userInfo?.partner &&
                        <Tabs.Tab key='partner-panel' title='合伙人后台' >
                            <PartnerPanel ref={partnerPanelRef}/>
                        </Tabs.Tab>
                    }
                </Tabs>
            </div>
            <ImagePopup visible={showIphonePopup}
                title="请长按二维码"
                subTitle="关注公众号，输入“盲盒”获取入口地址"
                showCloseButton={false}
                image="https://wittyai.cn/common-files/tanmostar_gzh.jpg" 
                height={60} 
                imageWidth={260}/>
        </PullToRefresh>
    )
}

function BlindBoxPage(props) {
    const dispatch = useDispatch()
    const agreementPopupRef = useRef()
    const [savePopupVisible, setSavePopupVisible] = useState(false)
    const [savePopupParams, setSavePopupParams] = useState({})
    const [fetchPopupVisible, setFetchPopupVisible] = useState(false)
    const [fetchPopupParams, setFetchPopupParams] = useState({})
    const [customerPopupVisiable, setCustomerPopupVisiable] = useState(false)
    const userInfo = useSelector((state) => state.global.userInfo)
    const sourceUserId = useSelector((state) => state.global.sourceUserId)

    const onClickPoster = (code) => {
        console.log("click poster, code:" + code)
    }

    const onClickSaveNoteButton = (skuCode, gender) => {
        if(window.__wxjs_environment === 'miniprogram'
            && /iPhone|Mac|iPad|iPod/.test(window.navigator.userAgent)) {
            Toast.show('苹果用户不支持虚拟支付')
            return
        }
        setSavePopupVisible(true)
        savePopupParams.skuCode = skuCode
        savePopupParams.gender = gender
        savePopupParams.price = skuInfoMap[skuCode].price
        setSavePopupParams({
            ...savePopupParams
        })
    }

    const onClickFetchNoteButton = (skuCode, gender) => {
        if(window.__wxjs_environment === 'miniprogram'
            && /iPhone|Mac|iPad|iPod/.test(window.navigator.userAgent)) {
            Toast.show('苹果用户不支持虚拟支付')
            return
        }
        setFetchPopupVisible(true)
        fetchPopupParams.skuCode = skuCode
        fetchPopupParams.gender = gender
        fetchPopupParams.count = skuInfoMap[skuCode].count
        fetchPopupParams.price = skuInfoMap[skuCode].price
        setFetchPopupParams({
            ...fetchPopupParams
        })
    }

    const onCloseSaveNotePopup = () => {
        setSavePopupVisible(false)
    }

    const onCloseFetchNotePopup = () => {
        setFetchPopupVisible(false)
    }

    const onSaveNotePaySuccess = () => {
        onCloseSaveNotePopup()
        //切换到我的纸条页面
        dispatch(blindBoxSwitchTab({
            tabActiveKey: "my-note"
        }))
        //切换到我放入的纸条标签
        dispatch(switchMyNoteTab({
            myNoteTabActiveIndex: 0
        }))
    }

    const onFetchNotePaySuccess = () => {
        onCloseFetchNotePopup()
        //切换到我的纸条页面
        dispatch(blindBoxSwitchTab({
            tabActiveKey: "my-note"
        }))
        //切换到我抽到的纸条标签
        dispatch(switchMyNoteTab({
            myNoteTabActiveIndex: 1
        }))
    }

    const openCustomerPopup = () => {
        setCustomerPopupVisiable(true)
    }

    const closeCustomerPopup = () => {
        setCustomerPopupVisiable(false)
    }

    const openAgreementPopup = () => {
        if(agreementPopupRef && agreementPopupRef.current) {
            agreementPopupRef.current.show()
        }
    }

    const onClickLeftRedirect = () => {
        if(window.__wxjs_environment === 'miniprogram') {
            //小程序内web-view打开时跳转到首页
            window.wx.miniProgram.switchTab({
                url: '/pages/index/index'
            })
        }
    }

    const onClickRightRedirect = () => {
        if(window.__wxjs_environment === 'miniprogram') {
            //小程序内web-view打开时跳转到首页
            window.wx.miniProgram.switchTab({
                url: '/pages/index/index'
            })
        }
    }

    return (
    <div className={styles.blindBoxIndex}>
        <Swiper className={styles.imageSwiper} autoplay loop>
            {swiperItems.map(item => <Swiper.Item className={styles.swiperItem} key={item.code}>
                <Image fit='cover' className={styles.swiperItemImage} src={item.image} onClick={() => onClickPoster(item.code)}/>
            </Swiper.Item>)}
        </Swiper>
        <div className={styles.pageBodyWrapper}>
            <div className={styles.bodyLeft}>
                <div style={{ position: 'relative', width: '100%', height: '6vh', minHeight: '40px', borderRadius: '6px'}}
                    onClick={onClickLeftRedirect}>
                    {/* 该段代码可解决wx-open-launch-weapp标签样式设置问题，详见：https://github.com/toFrankie/blog/issues/154 */}
                    <Image fit='cover' className={styles.redirectImage} src={tanmoWallImage}/>
                    <wx-open-launch-weapp id="launch-btn"
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        appid="wx06fdffb4a010b23a">
                        <script type="text/wxtag-template">
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0}}/>
                        </script>
                    </wx-open-launch-weapp>
                </div>
                <div className={styles.operateArea}>
                    <Image fit='cover' className={styles.personImageArea} src={manBoxImage}/>
                    <div className={`${styles.buttonCommon} ${styles.saveNoteButton}`} onClick={() => onClickSaveNoteButton('SAVE_MAN_NOTE', 1)}>存一张男生纸条</div>
                    <div className={`${styles.buttonCommon} ${styles.fetchNoteButton}`} onClick={() => onClickFetchNoteButton('EXTRACT_MAN_NOTE_1', 1)}>取一张男生纸条</div>
                    <div className={styles.buttonList}>
                        <div className={`${styles.buttonCommon} ${styles.buttonItem}`} onClick={() => onClickFetchNoteButton('EXTRACT_MAN_NOTE_5', 1)}>
                            <Image className={styles.heartGifImage} src={heartGif}/>
                            <div>5连抽</div>
                        </div>
                        <div className={`${styles.buttonCommon} ${styles.buttonItem}`} onClick={() => onClickFetchNoteButton('EXTRACT_MAN_NOTE_10', 1)}>
                            <Image className={styles.heartGifImage} src={heartGif}/>
                            <div>10连抽</div>
                        </div>
                    </div>
                    <div className={`${styles.buttonCommon}  ${styles.fetchNoteButton} ${styles.gifImageBigButton}`} onClick={() => onClickFetchNoteButton('EXTRACT_MAN_NOTE_20', 1)}>
                        <Image className={styles.heartGifImage} src={heartGif}/>
                        <div>20连抽</div>
                    </div>
                </div>
            </div>
            <div className={styles.bodyRight}>
                <div style={{ position: 'relative', width: '100%', height: '6vh', minHeight: '40px', borderRadius: '6px'}}
                    onClick={onClickRightRedirect}>
                    {/* 该段代码可解决wx-open-launch-weapp标签样式设置问题，详见：https://github.com/toFrankie/blog/issues/154 */}
                    <Image fit='cover' className={styles.redirectImage} src={matchChatImage}/>
                    <wx-open-launch-weapp id="launch-btn"
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        appid="wxed26467ca7385f48"
                        path="pages/match-chat/match-chat">
                        <script type="text/wxtag-template">
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0}}/>
                        </script>
                    </wx-open-launch-weapp>
                </div>
                <div className={styles.operateArea}>
                    <Image fit='cover' className={styles.personImageArea} src={womanBoxImage}/>
                    <div className={`${styles.buttonCommon} ${styles.saveNoteButton}`} onClick={() => onClickSaveNoteButton('SAVE_WOMAN_NOTE', 0)}>存一张女生纸条</div>
                    <div className={`${styles.buttonCommon} ${styles.fetchNoteButton}`} onClick={() => onClickFetchNoteButton('EXTRACT_WOMAN_NOTE_1', 0)}>取一张女生纸条</div>
                    <div className={styles.buttonList}>
                        <div className={`${styles.buttonCommon} ${styles.buttonItem}`} onClick={() => onClickFetchNoteButton('EXTRACT_WOMAN_NOTE_5', 0)}>
                            <Image className={styles.heartGifImage} src={heartGif}/>
                            <div>5连抽</div>
                        </div>
                        <div className={`${styles.buttonCommon} ${styles.buttonItem}`} onClick={() => onClickFetchNoteButton('EXTRACT_WOMAN_NOTE_10', 0)}>
                            <Image className={styles.heartGifImage} src={heartGif}/>
                            <div>10连抽</div>
                        </div>
                    </div>
                    <div className={`${styles.buttonCommon}  ${styles.fetchNoteButton} ${styles.gifImageBigButton}`} onClick={() => onClickFetchNoteButton('EXTRACT_WOMAN_NOTE_20', 0)}>
                        <Image className={styles.heartGifImage} src={heartGif}/>
                        <div>20连抽</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.bottomButtonList}>
            <div className={styles.bottomButton} onClick={openAgreementPopup}>用户协议</div>
            <div className={styles.verticalSeparator}/>
            <div className={styles.bottomButton} onClick={openCustomerPopup}>联系客服</div>
            <div className={styles.verticalSeparator}/>
            <div className={styles.bottomButton} onClick={openCustomerPopup}>投诉举报</div>
            <div className={styles.verticalSeparator}/>
            <div className={styles.bottomButton} onClick={openAgreementPopup}>V{frontVersion}</div>
            <div className={styles.verticalSeparator}/>
            <div className={styles.bottomButton} onClick={openAgreementPopup}>U{userInfo?.userId}</div>
            {
                userInfo?.partner && null != sourceUserId && <>
                    <div className={styles.verticalSeparator}/>
                    <div className={styles.bottomButton} onClick={openAgreementPopup}>S{sourceUserId}</div>
                </>
            }
        </div>
        <ImagePopup visible={customerPopupVisiable} 
            title="长按二维码添加客服微信" 
            image={kefuWechatImage} 
            height={60} 
            imageWidth={260}
            onClose={closeCustomerPopup} 
            onMaskClick={closeCustomerPopup}/>
        <AgreementPopup ref={agreementPopupRef}/>
        <SaveNotePopup visible={savePopupVisible} params={savePopupParams} 
            onClose={onCloseSaveNotePopup} onPaySuccess={onSaveNotePaySuccess}/>
        <FetchNotePopup visible={fetchPopupVisible} params={fetchPopupParams} 
            onClose={onCloseFetchNotePopup} onPaySuccess={onFetchNotePaySuccess}/>
    </div>)
}

function SaveNotePopup({visible, params = {}, onClose = () => {}, onPaySuccess = () => {}}) {
    const [cityName, setCityName] = useState(defaultCityName)
    const [cityValue, setCityValue] = useState([])
    const [fileList, setFileList] = useState([])
    const [textValue, setTextValue] = useState('')
    const [wechatId, setWechatId] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)
    const [compressedCnt, setCompressedCnt] = useState(0)
    const sourceUserId = useSelector((state) => state.global.sourceUserId)
    const {gender, price, skuCode} = params
    let tempCityName = null
    const onClickClearLocation = (event) => {
        //阻止事件冒泡
        event.stopPropagation(); 
        setCityName('不限城市')
        setCityValue([])
        tempCityName = null
    }

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success: (result) => {
                    // 将 Blob 转换为 File
                    resolve(new File([result], file.name, {
                        type: result.type, // 继承 Blob 的 MIME 类型
                        lastModified: Date.now(), // 设置最后修改时间
                    }))
                },
                error(err) {
                    reject(err)
                },
            })
        })
    }

    const uploadImage = (file) => {
        return new Promise((resolve, reject) => {
            // 上传
            requestHttp('/blindbox/api/uploadPhoto', { photo: file }, 'POST', { 'content-type': 'multipart/form-data' })
            .then(res => {
                file.uploadInfo = res.data
                resolve(file)
            }).catch(errorMsg => {
                reject(errorMsg)
            })
        })
    }

    const beforeUploadImage = (file) => {
        if (file.size > 10 * 1024 * 1024) {
            Toast.show({content: '请选择小于10M的图片'})
            return null
        }
        if(compressedCnt === 0) {
            Toast.show({
                icon: 'loading',
                content: '',
                duration: 5000
            })
        }
        setCompressedCnt(compressedCnt + 1)
        return new Promise((resolve, reject) => {
            compressImage(file)
            .then(photoFile => uploadImage(photoFile))
            .then(photoFile => {
                if(compressedCnt <= 1) {
                    Toast.clear()
                }
                setCompressedCnt(compressedCnt - 1)
                resolve(photoFile)
            })
            .catch(errorMsg => {
                if(compressedCnt <= 1) {
                    Toast.clear()
                }
                setCompressedCnt(compressedCnt - 1)
                setTimeout(() => {
                    Toast.show({
                        icon: 'fail',
                        content: errorMsg,
                        duration: 2000
                    })
                }, 200)
                resolve()
            })
        })
    }

    const onUploadImage = (file) => {
        return file.uploadInfo
    }

    const clearSavePopup = () => {
        setCityName(defaultCityName)
        setCityValue([])
        setTextValue('')
        setFileList([])
        setWechatId('')
    }
    const onClickConfirmButton = () => {
        if(!textValue) {
            Toast.show({
                content: '请填写纸条内容',
                duration: 1000
            })
            return
        }
        if(!wechatId) {
            Toast.show({
                content: '请填写微信号',
                duration: 1000
            })
            return
        }
        setButtonLoading(true)
        requestHttp('/blindbox/api/createNoteAndPrePay', {
            content: {
                text: textValue,
                files: fileList
            },
            gender: gender,
            wechatId: wechatId,
            skuCode: skuCode,
            cityName: cityName === defaultCityName ? null : cityName,
            sourceUserId: sourceUserId
        }, 'POST').then(res => {
            setButtonLoading(false)
            let env = launchWxPayByJSBridge(res.data, () => {
                clearSavePopup()
                onPaySuccess()
            })
            if(env === 'miniprogram') {
                Dialog.confirm({
                    title: '温馨提示',
                    message: '是否支付成功？如支付过程中遇到问题可联系客服处理哦',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: '已支付成功',
                    cancelButtonText: '支付有问题'
                }).then(res => {
                    clearSavePopup()
                    onPaySuccess()
                }).catch(res => {
                    Toast.show({content: '请点击页面下方按钮联系客服', duration: 2000})
                })
            }
        }).catch(errorMsg => {
            setButtonLoading(false)
            Toast.show({
                icon: 'fail',
                content: errorMsg,
                duration: 2000
            })
        })
    }
    const textAreaPlaceholder = "本平台均为高质量纸条内容，禁止填写一切违规信息！（包括但不限于黄、赌、毒、诈骗等）\n如因违规信息导致纸条被下架平台概不负责。\n建议多放几张图片哟，第一感觉很重要~"
    return (
        <Popup
            visible={visible}
            showCloseButton
            onMaskClick={() => {
                onClose()
            }}
            onClose={() => {
                onClose()
            }}
            bodyStyle={{
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px'
            }}>
            <div className={styles.savePopupBody}>
                <div className={styles.popupBodyTitle}>放入1张{gender == 1 ? '男':'女'}生纸条</div>
                <div className={styles.contentArea}>
                    <Area
                        popup={{
                            round: true,
                        }}
                        title='请选择城市'
                        areaList={areaList}
                        value={cityValue}
                        columnsNum={2}
                        placeholder={['', '']}
                        visibleItemCount={9}
                        onChange={(val, options) => {
                            tempCityName = options[1].text   
                        }}
                        onConfirm={v => {
                            setCityValue(v)
                            setCityName(tempCityName)
                        }}>
                        {(_, selectRows, actions) => {
                            return (
                                <div className={styles.locationWrapper}>
                                    <Image className={styles.locationIcon} src={locationIcon}/>
                                    <div className={styles.cityName} onClick={() => actions.open()}>{cityName}</div>
                                    {cityName != '不限城市' && <CloseCircleOutline className={styles.closeCircleOutline} onClick={(event) => onClickClearLocation(event)}/>}
                                </div>
                            )
                        }}
                    </Area>
                    <Divider />
                    <div className={styles.scrollView}>
                        <TextArea className={styles.savePopupTextArea} 
                            placeholder={textAreaPlaceholder} 
                            value={textValue}
                            onChange={value => setTextValue(value)}
                            autoSize={{ minRows: 5, maxRows: 1000 }}
                            maxLength={1000}/>
                        <div className={styles.uploadImageList}>
                            <ImageUploader
                                value={fileList}
                                maxCount={9}
                                multiple
                                onChange={setFileList}
                                beforeUpload={beforeUploadImage}
                                upload={onUploadImage}/>
                        </div>
                    </div>
                    <div className={styles.wxInputWrapper}>
                        <div className={styles.wxInputLeft}>
                            <div className={styles.wxInputHint}>我的微信号</div>
                            <RightOutline />
                        </div>
                        <Input className={styles.wxInputRight} 
                            value={wechatId}
                            onChange={value => setWechatId(value)}
                            placeholder='只有抽到该纸条的人才能看到'/>
                    </div>
                    {/* <div className={styles.displaySwitchWrapper}>
                        <div className={styles.displaySwitchDesc}>展示在广场</div>
                        <Switch
                            defaultChecked
                            style={{
                                '--checked-color': '#07c160',
                                '--height': '25px',
                                '--width': '50px',
                            }}/>
                    </div> */}
                </div>
                <div className={styles.priceDesc}>¥{price}元</div>
                <Button className={styles.confirmButton} block color='primary' size='large' 
                    loading={buttonLoading} disabled={buttonLoading} onClick={onClickConfirmButton}>确认放入</Button>
            </div>
        </Popup>
    )
}

function FetchNotePopup({visible, params = {}, onClose = () => {}, onPaySuccess = () => {}}) {
    let {skuCode, gender, count, price} = params
    const [buttonLoading, setButtonLoading] = useState(false)
    const sourceUserId = useSelector((state) => state.global.sourceUserId)
    const onClickExtractButton = () => {
        setButtonLoading(true)
        requestHttp('/blindbox/api/extractNotePrePay', {
            gender: gender,
            skuCode: skuCode,
            sourceUserId: sourceUserId
        }, 'POST').then(res => {
            setButtonLoading(false)
            let env = launchWxPayByJSBridge(res.data, onPaySuccess)
            if(env === 'miniprogram') {
                Dialog.confirm({
                    title: '温馨提示',
                    message: '是否支付成功？如支付过程中遇到问题可联系客服处理哦',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: '已支付成功',
                    cancelButtonText: '支付有问题'
                }).then(res => {
                    onPaySuccess()
                }).catch(res => {
                    Toast.show({content: '请点击页面下方按钮联系客服', duration: 2000})
                })
            }
        }).catch(errorMsg => {
            setButtonLoading(false)
            Toast.show({
                icon: 'fail',
                content: errorMsg,
                duration: 2000
            })
        })
    }
    return (
        <Popup
            visible={visible}
            showCloseButton
            onMaskClick={() => {
                onClose()
            }}
            onClose={() => {
                onClose()
            }}
            bodyStyle={{
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px'
            }}>
            <div className={styles.savePopupBody}>
                <div className={styles.popupBodyTitle}>抽取{count}张{gender == 1 ? '男':'女'}生纸条</div>
                <div className={styles.savePopupPersonImage}>
                    <Image fit="cover" src={gender == 1 ? manBoxImage : womanBoxImage}/>
                </div>
                <div className={styles.priceDesc}>¥{price}元</div>
                <Button className={styles.confirmButton} block color='primary' size='large' 
                    loading={buttonLoading} disabled={buttonLoading} onClick={onClickExtractButton}>确认抽取</Button>
            </div>
        </Popup>
    )
}

const MyNotePage = forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const tabActiveKey = useSelector((state) => state.global.blindBoxState.tabActiveKey)
    const myNoteTabActiveIndex = useSelector((state) => state.global.blindBoxState.myNoteTabActiveIndex)
    const [savedNoteList, setSavedNoteList] = useState([])
    const [fetchedNoteList, setFetchedNoteList] = useState([])
    const switchAreaRef = useRef()
    const [noteListHeight, setNoteListHeight] = useState(300)
    const [savedNoteHasMore, setSavedNoteHasMore] = useState(true)
    const [fetchedNoteHasMore, setFetchedNoteHasMore] = useState(true)

    useEffect(() => {
        //动态计算切换标签以下的页面剩余高度
        if(switchAreaRef.current) {
            const rect = switchAreaRef.current.getBoundingClientRect()
            setNoteListHeight(window.innerHeight - rect.bottom)
        }
    }, [tabActiveKey])

    useEffect(() => {
        if(tabActiveKey === 'my-note') {
            refreshMyNote(0)
        }
    }, [tabActiveKey, myNoteTabActiveIndex])

    const refreshMyNote = (start) => {
        return new Promise((resolve, reject) => {
            let limit = 20
            if(myNoteTabActiveIndex === 0) {
                //查询我放入的纸条
                requestHttp('/blindbox/api/pagingQueryMySavedNoteRecords', {
                    start: Number.isFinite(start) ? start : savedNoteList.length,
                    limit: limit
                }).then(res => {
                    if(res.data && res.data.length > 0) {
                        if(start === 0) {
                            setSavedNoteList(res.data)
                        } else {
                            setSavedNoteList([...savedNoteList, ...res.data])
                        }
                    }
                    if(!res.data || res.data.length === 0) {
                        if(savedNoteHasMore) {
                            setSavedNoteHasMore(false)
                        }
                    } else {
                        if(!savedNoteHasMore) {
                            setSavedNoteHasMore(true)
                        }
                    }
                    resolve()
                }).catch(errorMsg => {
                    reject()
                })
            } else {
                //查询我抽到的纸条
                requestHttp('/blindbox/api/pagingQueryExtractedNoteRecords', {
                    start: Number.isFinite(start) ? start : fetchedNoteList.length,
                    limit: limit
                }).then(res => {
                    if(res.data && res.data.length > 0) {
                        if(start === 0) {
                            setFetchedNoteList(res.data)
                        } else {
                            setFetchedNoteList([...fetchedNoteList, ...res.data])
                        }
                    }
                    if(!res.data || res.data.length === 0) {
                        if(fetchedNoteHasMore) {
                            setFetchedNoteHasMore(false)
                        }
                    } else {
                        if(!fetchedNoteHasMore) {
                            setFetchedNoteHasMore(true)
                        }
                    }
                    resolve()
                }).catch(errorMsg => {
                    reject()
                })
            }
        })
    }

    useImperativeHandle(ref, () => {
        return {
            triggerRefreshMyNote() {
                return refreshMyNote(0)
            }
        }
    })

    const switchTag = (index) => {
        dispatch(switchMyNoteTab({
            myNoteTabActiveIndex: index
        }))
    }

    const removeFromList = (noteRecord) => {
        Dialog.confirm({
            title: '温馨提示',
            message: '纸条销毁后无法找回，别人也无法再次抽取到您的纸条，确定要这么做吗？'
        }).then(res => {
            if(myNoteTabActiveIndex === 0) {
                requestHttp('/blindbox/api/removeNoteRecord', {
                    recordId: noteRecord.id
                }, 'POST').then(() => {
                    setSavedNoteList(savedNoteList.filter(note => note.id != noteRecord.id))
                }).catch(errorMsg => {
                    Toast.show({
                        icon: 'fail',
                        content: errorMsg,
                        duration: 2000
                    })
                })
            } else {
                requestHttp('/blindbox/api/removeExtractRecord', {
                    recordId: noteRecord.id
                }, 'POST').then(() => {
                    setFetchedNoteList(fetchedNoteList.filter(note => note.id != noteRecord.id))
                }).catch(errorMsg => {
                    Toast.show({
                        icon: 'fail',
                        content: errorMsg,
                        duration: 2000
                    })
                })
            }
        }).catch(res => {
            //do nothing
        })
    }

    return (
        <div className={styles.myNotePage}>
            <div ref={switchAreaRef} className={styles.noteSwitchTag}>
                <div className={`${styles.noteSwitchTagItem} ${myNoteTabActiveIndex === 0 ? styles.activeNoteSwitchTagItem : ''}`}
                    onClick={() => switchTag(0)}>
                    我放入的纸条
                </div>
                <div className={`${styles.noteSwitchTagItem} ${myNoteTabActiveIndex === 1 ? styles.activeNoteSwitchTagItem : ''}`}
                    onClick={() => switchTag(1)}>
                    我抽到的纸条
                </div>
            </div>
            <div className={styles.noteList} style={{height: `${noteListHeight}px`}}>
                {myNoteTabActiveIndex === 0 ? <NoteRecordList noteRecordList={savedNoteList} loadMore={refreshMyNote} hasMore={savedNoteHasMore} showOperateLine removeFromList={removeFromList}/>
                    : <NoteRecordList noteRecordList={fetchedNoteList} loadMore={refreshMyNote} hasMore={fetchedNoteHasMore}/>}
            </div>
        </div>
    )
})


const AuditCenter = forwardRef((props, ref) => {
    const tabActiveKey = useSelector((state) => state.global.blindBoxState.tabActiveKey)
    const [activeIndex, setActiveIndex] = useState(0)
    const [noteList0, setNoteList0] = useState([])
    const [noteList1, setNoteList1] = useState([])
    const switchAreaRef = useRef()
    const [noteListHeight, setNoteListHeight] = useState(300)
    const [note0HasMore, setNote0HasMore] = useState(true)
    const [note1HasMore, setNote1HasMore] = useState(true)

    useEffect(() => {
        //动态计算切换标签以下的页面剩余高度
        if(switchAreaRef.current) {
            const rect = switchAreaRef.current.getBoundingClientRect()
            setNoteListHeight(window.innerHeight - rect.bottom)
        }

    }, [])

    useEffect(() => {
        if(tabActiveKey === 'audit-center') {
            refreshMyNote(0)
        }
    }, [tabActiveKey, activeIndex])

    const refreshMyNote = (start) => {
        return new Promise((resolve, reject) => {
            let limit = 20
            if(activeIndex === 0) {
                requestHttp('/blindbox/api/pagingQueryAuditNoteRecords', {
                    start: Number.isFinite(start) ? start : noteList0.length,
                    limit: limit,
                    status: 'WAIT_AUDIT'
                }).then(res => {
                    if(res.data && res.data.length > 0) {
                        if(start === 0) {
                            setNoteList0(res.data)
                        } else {
                            setNoteList0([...noteList0, ...res.data])
                        }
                    }
                    if(!res.data || res.data.length < limit) {
                        if(note0HasMore) {
                            setNote0HasMore(false)
                        }
                    }
                    resolve()
                }).catch(errorMsg => {
                    reject()
                })
            } else {
                requestHttp('/blindbox/api/pagingQueryAuditNoteRecords', {
                    start: Number.isFinite(start) ? start : noteList1.length,
                    limit: limit
                }).then(res => {
                    if(res.data && res.data.length > 0) {
                        if(start === 0) {
                            setNoteList1(res.data)
                        } else {
                            setNoteList1([...noteList1, ...res.data])
                        }
                    }
                    if(!res.data || res.data.length < limit) {
                        if(note1HasMore) {
                            setNote1HasMore(false)
                        }
                    }
                    resolve()
                }).catch(errorMsg => {
                    reject()
                })
            }
        })
    }

    const removeFromList = (noteRecord) => {
        if(activeIndex === 0) {
            setNoteList0(noteList0.filter(note => note.id != noteRecord.id))
        } else {
            setNoteList1(noteList1.filter(note => note.id != noteRecord.id))
        }
    }

    useImperativeHandle(ref, () => {
        return {
            triggerRefreshMyNote: () => {
                return refreshMyNote(0)
            }
        }
    })

    const switchTag = (index) => {
        setActiveIndex(index)
    }

    return (
        <div className={styles.myNotePage}>
            <div ref={switchAreaRef} className={styles.noteSwitchTag}>
                <div className={`${styles.noteSwitchTagItem} ${activeIndex === 0 ? styles.activeNoteSwitchTagItem : ''}`}
                    onClick={() => switchTag(0)}>
                    待审核
                </div>
                <div className={`${styles.noteSwitchTagItem} ${activeIndex === 1 ? styles.activeNoteSwitchTagItem : ''}`}
                    onClick={() => switchTag(1)}>
                    全部纸条
                </div>
            </div>
            <div className={styles.noteList} style={{height: `${noteListHeight}px`}}>
                {activeIndex === 0 ? <NoteRecordList auditMode noteRecordList={noteList0} loadMore={refreshMyNote} hasMore={note0HasMore} removeFromList={removeFromList}/> 
                    : <NoteRecordList auditMode noteRecordList={noteList1} loadMore={refreshMyNote} hasMore={note1HasMore} removeFromList={removeFromList}/> }
            </div>
        </div>
    )
})

const EmptyData = (props) => {
    return (
        <div className={styles.emptyData}>
            <img className={styles.emptyDataImage} src={emptyImage} />
            <div className={styles.emptyDataText}>暂无数据</div>
            <div>(如刚支付完成请过一会儿再查看)</div>
        </div>
    )
}

const NoteRecordList = ({ noteRecordList = [], loadMore = () => {}, hasMore, auditMode = false, removeFromList = () => {}, showOperateLine = false}) => {
    const appConfig = useSelector((state) => state.global.appConfig)
    let noticeSceneCode = 'myNoteNoticePopup'
    const noticeConfig = appConfig?.noticeConfigMap[noticeSceneCode]
    return noteRecordList.length === 0 ? <EmptyData /> : (
        <>
            <KeepAlive>
                <MemoryNoticeBar className={styles.memoryNoticeBar} sceneCode={noticeSceneCode} noticeConfig={noticeConfig} />
                <List>
                    {noteRecordList.map(noteRecord => 
                        <List.Item key={noteRecord.id}>
                            <NoteRecord noteRecord={noteRecord} auditMode={auditMode} removeFromList={removeFromList} showOperateLine={showOperateLine}/>
                        </List.Item>)}
                </List>
            </KeepAlive>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore}/>
        </>
    )
}

const NoteRecord = ({ noteRecord = {}, auditMode = false, removeFromList = () => {}, showOperateLine = false}) => {
    const [sheetVisiable, setSheetVisiable] = useState(false)
    const [initExtractCnt, setInitExtractCnt] = useState('')
    const actions = [
        { text: '暴力血腥', key: 'code1' },
        { text: '色情低俗', key: 'code2' },
        { text: '广告营销', key: 'code3' },
        { text: '不友善的内容', key: 'code4' },
        { text: '骚扰他人', key: 'code5' },
        { text: '其他', key: 'other' }
    ]

    const onSheetAction = (action, index) => {
        //调拒绝接口
        requestHttp('/blindbox/api/auditNoteRecord', {
            recordId: noteRecord.id,
            auditResult: 'reject',
            rejectReason: action.text
        }, 'POST').then(() => {
            setSheetVisiable(false)
            removeFromList(noteRecord)
        }).catch(errorMsg => {
            Toast.show({
                icon: 'fail',
                content: errorMsg,
                duration: 2000
            })
        })
    }

    const onClickPassButton = () => {
        //调通过接口
        requestHttp('/blindbox/api/auditNoteRecord', {
            recordId: noteRecord.id,
            auditResult: 'pass',
            initExtractCnt: initExtractCnt && initExtractCnt > 0 ? initExtractCnt : null
        }, 'POST').then(() => {
            setSheetVisiable(false)
            removeFromList(noteRecord)
        }).catch(errorMsg => {
            Toast.show({
                icon: 'fail',
                content: errorMsg,
                duration: 2000
            })
        })
    }

    const onSheetClose = () => {
        setSheetVisiable(false)
    }

    const onClickRejectButton = () => {
        setSheetVisiable(true)
    }

    const onClickCopyButton = () => {
        navigator.clipboard.writeText(noteRecord.wechatId)
        Toast.show('微信号已复制')
    }

    const onClickDestroyButton = () => {
        removeFromList(noteRecord)
    }

    return (
        <div className={styles.noteRecord}>
            <div className={styles.locationLine}>
                <div className={styles.locationLineLeft}>
                    <div className={styles.locationWrapper}>
                        <Image className={styles.locationIcon} src={locationIcon}/>
                        <div className={styles.cityName}>{noteRecord.originalCityName ? noteRecord.originalCityName : '不限城市'}</div>
                    </div>
                    {null != noteRecord.gender && <div className={styles.noteRecordGender}>{noteRecord.gender == 1 ? '[男生盒子]' : '[女生盒子]'}</div>}
                </div>
                {noteRecord.bizTime && <div className={styles.bizTime}>{noteRecord.bizTime}</div>}
            </div>
            <Divider/>
            <Ellipsis className={styles.ellipsisText} direction='end' rows={3} content={noteRecord.content.text} expandText='展开' collapseText='收起'/>
            {noteRecord.content.files && noteRecord.content.files.length > 0 && 
                <ImageUploader
                    value={noteRecord.files}
                    showUpload={false}
                    deletable={false}
                    maxCount={9}
                    multiple/>}
            <Divider/>
            <div className={styles.wechatId}>
                <div>微信号：{noteRecord.wechatId}</div>
                <div className={styles.copyButton} onClick={onClickCopyButton}>复制</div>
            </div>
            { noteRecord.status === 'AUDIT_REJECT' &&
                <div className={styles.auditResult}>
                    <div>审核结果：拒绝</div>
                    <div>拒绝原因：{noteRecord?.feature?.REJECT_REASON}</div>
                </div>
            }
            {showOperateLine && 
                <div className={styles.showOperateLine}>
                    <div className={styles.extractCntDesc}>已被{noteRecord.extractCnt}人抽到</div>
                    <div className={styles.destroyButton} onClick={onClickDestroyButton}>销毁</div>
                </div>
            }
            {auditMode && <div>状态：{noteRecord.statusDesc}</div>}
            {auditMode && 'DELETED' != noteRecord.status && <div className={styles.operateButtonList}>
                <div className={styles.extractCntDesc}>已被{noteRecord.extractCnt}人抽到</div>
                <div className={styles.initExtractCntInput}>
                    <Input placeholder='初始抽取次数' value={initExtractCnt} onChange={setInitExtractCnt}/>
                </div>
                <div className={`${styles.operateButton} ${styles.rejectButton}`} onClick={onClickRejectButton}>拒绝</div>
                <div className={styles.operateButton} onClick={onClickPassButton}>通过</div>
            </div>}
            <PopupActionSheet visible={sheetVisiable} actions={actions} onAction={onSheetAction} onClose={onSheetClose} inputTitle='请输入拒绝原因'/>
        </div>
    )
}
