import styles from './index.module.less'
import UserInfoArea from '../../components/user-info-area'
import { Ellipsis, Button } from 'antd-mobile'
import moment from 'moment'

export default function BottleShowCard({bottleRecord, userInfo, salvageRecord, onClickDeleteButton = (bottleRecord) => {}}) {

    const toMainPage = () => {
        if(window.__wxjs_environment === 'miniprogram') {
            window.wx.miniProgram.navigateTo({
                url: `/pages/main-page/main-page?userId=${userInfo.userId}`
            })
        }
    }

    return (
        <div className={styles.bottleShowCard}>
            {userInfo && userInfo.userId && <UserInfoArea className={styles.userInfoArea} userInfo={userInfo} onClick={toMainPage}/>}
            <Ellipsis className={styles.ellipsisText} 
                direction='end' 
                rows={3} 
                content={bottleRecord?.content?.text}
                expandText='展开'
                collapseText='收起' />
            <div className={styles.bottomLine}>
                <div className={styles.timeDesc}>
                    {moment(salvageRecord ? salvageRecord.gmtCreate : bottleRecord.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div className={styles.buttonList}>
                    <Button className={`${styles.buttonItem} ${styles.deleteButton}`} onClick={() => onClickDeleteButton(bottleRecord)}>删除</Button>
                    {salvageRecord && <Button className={`${styles.buttonItem} ${styles.detailButton}`} onClick={toMainPage}>查看主页</Button>}
                </div>
            </div>
        </div>
    )
}
