import styles from './index.module.less'
import { Image } from 'antd-mobile'
import maleIcon from '../../../images/male.png'
import femaleIcon from '../../../images/female.png'

export default function UserGenderIcon({gender}) {
    return null != gender && (
        <div className={styles.genderWrapper}>
            <Image className={styles.genderIcon} src={gender === 1 ? maleIcon : femaleIcon}/>
        </div>
    )
}