import styles from './index.module.less'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { NavBar, List, InfiniteScroll } from 'antd-mobile'
import moment from 'moment';
import { requestHttpHintWhenFail } from '../../../utils/request';

export default function IncomeDetail() {
    const navigate = useNavigate();
    const [orderList, setOrderList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    useEffect(() => {
        pagingQueryIncomeDetail()
    }, [])

    const pagingQueryIncomeDetail = (start) => {
        requestHttpHintWhenFail('/blindbox/api/pagingQueryIncomeDetail', {
            start: Number.isFinite(start) ? start : orderList.length,
            limit: 20
        }).then(res => {
            if(res.data && res.data.length > 0) {
                res.data.forEach(order => {
                    if(order?.sceneCode === 'EXTRACT_NOTE') {
                        order.sceneDesc = '【抽取纸条】'
                    } else if(order?.sceneCode === 'SAVE_NOTE') {
                        order.sceneDesc = '【存入纸条】'
                    } else if(order?.sceneCode === 'OPEN_PARTNER') {
                        order.sceneDesc = '【成为合伙人】'
                    } else {
                        order.sceneDesc = '【未知】'
                    }
                    if(order?.feature?.GENDER === 1) {
                        order.boxDesc = '[男生盒子]'
                    } else if(order?.feature?.GENDER === 0) {
                        order.boxDesc = '[女生盒子]'
                    } else {
                        order.boxDesc = ''
                    }
                })
                setOrderList([...orderList, ...res.data])
                if(!hasMore) {
                    setHasMore(true)
                }
            } else {
                if(hasMore) {
                    setHasMore(false)
                }
            }
        })
    }

    const loadMore = (isRetry) => {
        pagingQueryIncomeDetail()
    }

    const goBack = () => {
        navigate(-1)
    }
    return (
        <div>
            <NavBar onBack={goBack}>收益明细</NavBar>
            {(!orderList || orderList.length == 0) && <div className={styles.emptyDesc}>暂无收益</div>}
            {orderList && orderList.length > 0 && <div className={styles.orderList}>
                <List>
                    {orderList.map(order => 
                        <List.Item key={order.id}>
                            <IncomeOrderDetail order={order}/>
                        </List.Item>)}
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore}/>
            </div>}
        </div>
    )
}

export function IncomeOrderDetail({ order }) {
    return (
        <div className={styles.incomeOrderDetail}>
            <div className={styles.detailTopLine}>
                <div>{order?.sceneDesc}</div>
                <div>{moment(order.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
            <div className={styles.detailBodyLine}>
                <div className={styles.boxDesc}>{order?.boxDesc}</div>
                <div className={styles.priceWrapper}>
                    <div className={styles.orderPrice}>实付款:¥{order.price}</div>
                    <div className={styles.brokerage}>佣金:¥{order.brokerage}</div>
                </div>
            </div>
        </div>
    )
}
