import styles from './index.module.less';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { DotLoading, Toast } from 'antd-mobile'
import qs from "query-string";
import requestHttp from "../../utils/request";

/**
 * 适用场景：外部浏览器跳转打开微信小程序
 */
export default function MiniRedirect() {
    const {search} = useLocation()
    const {appName, path, query, envVersion} = qs.parse(search)

    useEffect(() => {
        if(null != appName) {
            let api = '/mall/promotion/getUrlLink'
            if(appName === 'tm_wall') {
                api = '/circle/api/getUrlLink'
            }
            requestHttp(api, {
                appName: appName,
                path: path,
                query: query,
                envVersion: envVersion
            }, 'GET').then(res => {
                console.log(res)
                window.location.replace(res.data);
            }).catch(errorMsg => {
                Toast.show({
                    icon: 'fail',
                    content: errorMsg ? errorMsg : '跳转失败',
                    duration: 1000
                })
            })
        }
    }, [])

    return (
        <div className={styles.miniRedirectPage}>
            <span style={{ fontSize: 24 }}>
                <DotLoading />
            </span>
        </div>
    )
}
