import { useState } from 'react'
import styles from './index.module.less'
import { ActionSheet } from 'antd-mobile'
import InputPopup from '../input-popup'

export default function PopupActionSheet({ visible = false, actions = [], onAction = () => {}, onClose = () => {}, inputTitle = '请输入'}) {
    const [inputPopupVisible, setInputPopupVisible] = useState(false)

    const onClickAction = (action, index) => {
        if(action.text === '其他') {
            //弹出一个输入框弹窗
            setInputPopupVisible(true)
        } else {
            onAction(action, index)
        }
    }

    const onClickInputConfirmButton = (inputValue) => {
        setInputPopupVisible(false)
        onAction({
            text: inputValue
        }, null)
    }

    const onClickInputPopupMask = () => {
        setInputPopupVisible(false)
    }

    return (
        <>
            <ActionSheet
                popupClassName={styles.popupActionSheet}
                visible={visible}
                actions={actions}
                onAction={onClickAction}
                onClose={onClose}
                cancelText='取消'/>
            <InputPopup visible={inputPopupVisible} 
                type='text' 
                title={inputTitle} 
                placeholder='请输入' 
                onClickButton={onClickInputConfirmButton}
                onMaskClick={onClickInputPopupMask}/>
        </>
    )
}

