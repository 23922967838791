import styles from './index.module.less';

export default function MemberCard({theme='light', cardInfo = {}, onClick = () => {}}) {

    return (
        <div className={`${styles[theme]} ${styles.memberCard} ${cardInfo.checked === true ? styles.memberCardActive : ''}`} onClick={() => onClick(cardInfo)}>
            <div className={styles.timeWrapper}>
                <span className={styles.time}>{cardInfo.time}</span>
                <span className={styles.timeDesc}>{cardInfo.timeDesc}</span>
            </div>
            <div className={styles.amountDesc}>{"￥" + cardInfo.amountDesc}</div>
            <div className={styles.bottomDesc}>{cardInfo.bottomDesc}</div>
        </div>
    )
}