import styles from './index.module.less'
import { Mask, Button, TextArea } from 'antd-mobile'

export default function InputAreaPopup({visible, title, value, 
    cfmBtnLoading = false,
    confirmButtonName = '确认',
    cancelButtonName = '取消',
    onValueChange = (val) => {},
    onMaskClick = () => {},
    onClickConfirmButton = () => {},
    onClickCancelButton = () => {}
}) {
    return (
        <Mask visible={visible} onMaskClick={onMaskClick}>
            <div className={styles.inputAreaPopup}>
                <div className={styles.title}>{title}</div>
                <div className={styles.inputArea}>
                    <TextArea placeholder='你想对TA说点什么呢' value={value} rows={5} maxLength={1024} onChange={onValueChange}/>
                </div>
                <div className={styles.buttonList}>
                    {cancelButtonName && <Button className={styles.cancelButton} fill='solid' onClick={onClickCancelButton}>{cancelButtonName}</Button>}
                    {confirmButtonName && <Button className={styles.confirmButton} color='primary' fill='solid' onClick={onClickConfirmButton} loading={cfmBtnLoading}>{confirmButtonName}</Button>}
                </div>
            </div>
        </Mask>
    )
}