import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        userInfo: null,
        sessionKey: null,
        sourceUserId: null,
        appConfig: {},
        //盲盒页面相关的状态
        blindBoxState: {
            //控制首页标签页的展示
            tabActiveKey: "blind-box",
            myNoteTabActiveIndex: 0
        }
    },
    reducers: {
        login: (state, action) => {
            state.userInfo = action.payload.userInfo
            state.sessionKey = action.payload.sessionKey
            state.sourceUserId = action.payload.sourceUserId
        },
        logout: (state) => {
            state.userInfo = null
        },
        blindBoxSwitchTab: (state, action) => {
            state.blindBoxState.tabActiveKey = null != action.payload.tabActiveKey ? action.payload.tabActiveKey : 'blind-box'
        },
        switchMyNoteTab: (state, action) => {
            state.blindBoxState.myNoteTabActiveIndex = null != action.payload.myNoteTabActiveIndex ? action.payload.myNoteTabActiveIndex : 0
        },
        setAppConfig: (state, action) => {
            state.appConfig = null != action.payload.appConfig ? action.payload.appConfig : {}
        }
    }
})

export const {login, logout, blindBoxSwitchTab, switchMyNoteTab, setAppConfig} = globalSlice.actions;
export default globalSlice.reducer;