import { Routes, Route } from "react-router-dom";
import { AliveScope } from 'react-activation';
import About from "./pages/about";
import BottleRecharge from "./pages/bottle-recharge";
import CircleRecharge from "./pages/circle-recharge";
import BottleHomepage from "./pages/bottle-homepage";
import BottleMessage from "./pages/bottle-message";
import IcpBeian from "./pages/icp-beian";
import MiniRedirect from "./pages/mini-redirect";
import BlindBox from "./pages/blind-box/index";
import AuthRedirect from "./pages/auth-redirect";
import CommonRedirect from "./pages/common-redirect";
import WxAuth from "./pages/wx-auth";
import IncomeDetail from "./pages/blind-box/income-detail";
import Promotion from "./pages/blind-box/promotion";
import DriftBottle from "./circle/pages/drift-bottle";
import MyBottle from "./circle/pages/my-bottle";
import './App.css';

function App() {
  return (
    <AliveScope>
      <Routes>
          <Route path="/" element={<IcpBeian />} />
          <Route path="/h5" element={<About />} />
          <Route path="/h5/about" element={<About />} />
          <Route path="/h5/bottle-recharge" element={<BottleRecharge />} />
          <Route path="/h5/circle-recharge" element={<CircleRecharge />} />
          <Route path="/h5/bottle-homepage" element={<BottleHomepage />} />
          <Route path="/h5/bottle-message" element={<BottleMessage />} />
          <Route path="/h5/icp-beian" element={<IcpBeian />} />
          <Route path="/h5/mini-redirect" element={<MiniRedirect />} />
          <Route path="/h5/auth-redirect" element={<AuthRedirect />} />
          <Route path="/h5/common-redirect" element={<CommonRedirect />} />
          <Route path="/h5/wx-auth" element={<WxAuth />} />
          <Route path="/h5/blind-box" element={<BlindBox />} />
          <Route path="/h5/blind-box/index" element={<BlindBox />} />
          <Route path="/h5/blind-box/income-detail" element={<IncomeDetail />} />
          <Route path="/h5/blind-box/promotion" element={<Promotion />} />
          <Route path="/h5/circle/drift-bottle" element={<DriftBottle />} />
          <Route path="/h5/circle/my-bottle" element={<MyBottle />} />
      </Routes>
    </AliveScope>
  );
}

export default App;
