import styles from './index.module.less'
import { useRef, useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Image, Mask, Button, Toast} from 'antd-mobile'
import { Dialog } from 'react-vant'
import qs from "query-string"
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/globalSlice';
import { requestHttpHintWhenFail } from '../../../utils/request'
import moment from 'moment';
import InputAreaPopup from '../../../components/input-area-popup'
import BottleShowPopup from '../components/bottle-show-popup'
import PopupActionSheet from '../../../components/popup-action-sheet'

import bottleLightBg from '../../images/bottle-light.jpeg'
import bottleBlackBg from '../../images/bottle-black.jpeg'
import filterIcon from '../../images/fliter.png'
import arrowRightIcon from '../../images/arrow-right.png'
import netIcon from '../../images/net.png'
import bottleIcon from '../../images/bottle.png'
import goldCoinIcon from '../../images/gold-coin.png'
import boatIcon from '../../images/boat.png'
import balloonIcon from '../../images/hot-balloon3.png'
import lampIcon from '../../images/lamp.png'

/**
 * 表白墙小程序内嵌的漂流瓶功能
 * 可支持小程序内部webview打开，或者公众号h5打开
 * 公众号h5打开场景下，跳转其他页面的功能暂不可用
 * 因为公众号内无法直接跳转到小程序页面，只能后期重写一套和小程序一样的h5页面，比如聊天页面、用户信息页面等
 * 后续的规划是基于此页面，逐渐重写出其他的小程序h5页面，使用户能通过公众号h5完整访问表白墙小程序所有功能，以及未来能够嵌入到混合app中
 */
let globalGender = -1
let currentSalvageInfo = {}
const actions = [
    { text: '政治敏感', key: 'code1' },
    { text: '暴力色情低俗', key: 'code2' },
    { text: '不友善的内容', key: 'code3' },
    { text: '涉嫌诈骗', key: 'code4' },
    { text: '造谣诽谤', key: 'code5' },
    { text: '广告营销', key: 'code6' },
    { text: '骚扰他人', key: 'code7' },
    { text: '其他', key: 'other' }
]

export default function DriftBottle() {
    const location = useLocation()
    const dispatch = useDispatch()
    const {appName, sessionKey, theme} = qs.parse(location.search)
    const [pageHeight, setPageHeight] = useState(600)
    const [bgTheme, setBgTheme] = useState(theme)
    const [maskVisible, setMaskVisible] = useState(false)
    const [filterVisible, setFilterVisible] = useState(false)
    const [tmpGender, setTmpGender] = useState(globalGender)
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [filterBtnLoading, setFilterBtnLoading] = useState(false)
    const [cfmBtnLoading, setCfmBtnLoading] = useState(false)
    const [bottleShowVisible, setBottleShowVisible] = useState(false)
    const [replyValue, setReplyValue] = useState('')
    const [salvageInfo, setSalvageInfo] = useState({})
    const [replyButtonLoading, setreplyButtonLoading] = useState(false)
    const [reportVisible, setReportVisible] = useState(false)
    const pageRef = useRef()
    const navigate = useNavigate()
    const userInfo = useSelector((state) => state.global.userInfo)
    useEffect(() => {
        document.title = '小纸条';
        //根据当前时间判断展示主题
        judgeCurrentTheme(theme)
        //动态计算切换标签以下的页面剩余高度
        if(pageRef.current) {
            const rect = pageRef.current.getBoundingClientRect()
            setPageHeight(window.innerHeight - rect.top)
        }
        requestHttpHintWhenFail('/circle/api/login', {
            appName: appName,
            sessionKey: sessionKey
        }, 'POST').then(res => {
            if(res.data) {
                dispatch(login({
                    userInfo: res.data.userInfo,
                    sessionKey: res.data.sessionKey
                }))
                let gender = res.data?.userInfo?.featureMap?.bottle_setting_gender
                if(null != gender) {
                    globalGender = gender
                    setTmpGender(gender)
                }
                checkInfoAndDialog(res.data.userInfo?.requiredFieldHint)
            }
        })
    }, [])

    const judgeCurrentTheme = (theme) => {
        if(!theme || 'light' === theme) {
            let time = moment(new Date()).format('HH:mm')
            if((time >= '19:00' && time <= '23:59') || (time >= '00:00' && time <= '05:00')) {
                //浅色模式下，夜间自动切换为深色
                setBgTheme('dark')
                document.documentElement.setAttribute('data-prefers-color-scheme', 'dark')
            } else {
                setBgTheme('light')
                document.documentElement.setAttribute('data-prefers-color-scheme', 'light')
            }
        } else {
            setBgTheme('dark')
            document.documentElement.setAttribute('data-prefers-color-scheme', 'dark')
        }
    }

    const checkInfoAndDialog = (requiredFieldHint) => {
        if(requiredFieldHint) {
            Dialog.confirm({
                className: styles.vantDialog,
                title: '温馨提示',
                message: `您的${requiredFieldHint}尚未完善，需要完善信息后才能使用哦~`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#6a68ed',
                confirmButtonText: '去完善'
            }).then(res => {
                if(window.__wxjs_environment === 'miniprogram') {
                    window.wx.miniProgram.navigateTo({
                        url: '/pages/info-edit/info-edit'
                    })
                }
            }).catch(res => {
                
            })
        }
    }

    const toMyBottlePage = () => {
        navigate('/h5/circle/my-bottle')
    }

    const playSalvageAnimation = () => {
        setMaskVisible(true)
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, 2300)
        })
    }

    const salvageBottleRequest = () => {
        return new Promise((resolve, reject) => {
            requestHttpHintWhenFail('/circle/api/salvageBottle', {
                gender: globalGender
            }, 'POST').then(res => {
                if(res.data) {
                    currentSalvageInfo = res.data
                }
                resolve()
            }).catch(err => {
                resolve()
            })
        })
    }

    const salvageBottle = () => {
        //校验金币余额
        if(userInfo?.totalCoin < 2) {
            Dialog.confirm({
                className: styles.vantDialog,
                title: '温馨提示',
                message: `您的金币余额不足`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#6a68ed',
                confirmButtonText: '获取更多金币',
                closeOnClickOverlay: true
            }).then(res => {
                if(window.__wxjs_environment === 'miniprogram') {
                    window.wx.miniProgram.navigateTo({
                        url: '/pages/gold-coin/gold-coin'
                    })
                }
            }).catch(res => {
                
            })
            return
        }
        if(userInfo?.requiredFieldHint) {
            //查询用户信息是否完善
            requestHttpHintWhenFail("/circle/api/queryRequiredFieldHint").then(res => {
                if(res.data) {
                    checkInfoAndDialog(res.data)
                } else {
                    salvageBottleCore()
                    //更新userInfo，下次不再调用查询接口
                    let newUserInfo = {...userInfo}
                    newUserInfo.requiredFieldHint = ''
                    dispatch(login({
                        userInfo: newUserInfo
                    }))
                }
            })
        } else {
            salvageBottleCore()
        }
    }

    const salvageBottleCore = () => {
        Promise.all([playSalvageAnimation(), salvageBottleRequest()])
        .then(res => {
            //暂停打捞动画播放
            setMaskVisible(false)
            if(currentSalvageInfo.userBottleRecord) {
                //修改金币数额
                let newUserInfo = {...userInfo}
                newUserInfo.totalCoin = newUserInfo.totalCoin - 2
                dispatch(login({
                    userInfo: newUserInfo,
                }))
                //展示瓶子
                setSalvageInfo(currentSalvageInfo)
                setBottleShowVisible(true)
            } else {
                Toast.show({content: '这片海域已经没有瓶子了，请稍后再来～', duration: 2000})
            }
        })
        .catch(errMsg => {
            setMaskVisible(false)
        })
    }

    const onClickBottleReplyButton = () => {
        if(!replyValue) {
            Toast.show({content: '请输入内容', duration: 1000})
            return
        }
        setreplyButtonLoading(true)
        requestHttpHintWhenFail('/circle/api/replyBottle', {
            bottleId: currentSalvageInfo?.userBottleRecord?.id,
            replyContent: {
                text: replyValue
            }
        }, 'POST').then(res => {
            setreplyButtonLoading(false)
            clearAndCloseBottlePopup()
            Toast.show({icon: 'success', content: '回应成功', duration: 800})
        }).catch(err => {
            setreplyButtonLoading(false)
        })
    }

    const onClickBottleCancelButton = () => {
        clearAndCloseBottlePopup()
    }

    const clearAndCloseBottlePopup = () => {
        //清空内容并关闭展示弹窗
        currentSalvageInfo = {}
        setSalvageInfo({})
        setReplyValue('')
        setBottleShowVisible(false)
    }

    const cancelFilterPanel = () => {
        //复原设置
        setTmpGender(globalGender)
        setFilterVisible(false)
    }

    const confirmFilterPanel = () => {
        setFilterBtnLoading(true)
        requestHttpHintWhenFail('/circle/api/modifyBottleSetting', {
            gender: tmpGender
        }, 'POST').then(res => {
            setFilterBtnLoading(false)
            globalGender = tmpGender
            setFilterVisible(false)
            Toast.show({icon: 'success', content: '设置成功', duration: 1000})
        }).catch(err => {
            setFilterBtnLoading(false)
        })
    }

    const throwBottle = () => {
        //校验金币余额
        if(userInfo?.totalCoin < 2) {
            Dialog.confirm({
                className: styles.vantDialog,
                title: '温馨提示',
                message: `您的金币余额不足`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#6a68ed',
                confirmButtonText: '获取更多金币',
                closeOnClickOverlay: true
            }).then(res => {
                if(window.__wxjs_environment === 'miniprogram') {
                    window.wx.miniProgram.navigateTo({
                        url: '/pages/gold-coin/gold-coin'
                    })
                }
            }).catch(res => {
                
            })
            return
        }
        if(userInfo?.requiredFieldHint) {
            //查询用户信息是否完善
            requestHttpHintWhenFail("/circle/api/queryRequiredFieldHint").then(res => {
                if(res.data) {
                    checkInfoAndDialog(res.data)
                } else {
                    setInputVisible(true)
                    //更新userInfo，下次不再调用查询接口
                    let newUserInfo = {...userInfo}
                    newUserInfo.requiredFieldHint = ''
                    dispatch(login({
                        userInfo: newUserInfo
                    }))
                }
            })
        } else {
            setInputVisible(true)
        }
    }

    const onInputMaskClick = () => {
        setInputVisible(false)
    }

    const onClickInputCancelButton = () => {
        setInputVisible(false)
    }

    const onClickInputConfirmButton = () => {
        if(!inputValue) {
            Toast.show({content: '请输入内容', duration: 1000})
            return
        }
        setCfmBtnLoading(true)
        requestHttpHintWhenFail('/circle/api/throwBottle', {
            content: {
                text: inputValue
            }
        }, 'POST').then(res => {
            setCfmBtnLoading(false)
            setInputVisible(false)
            setInputValue('')
            //修改金币数额
            let newUserInfo = {...userInfo}
            newUserInfo.totalCoin = newUserInfo.totalCoin - 2
            dispatch(login({
                userInfo: newUserInfo,
            }))
            Toast.show({icon: 'success', content: '扔成功啦', duration: 1000})
        }).catch(errMsg => {
            if(errMsg && errMsg.indexOf('违规') >= 0) {
                setInputValue('')
            }
            setCfmBtnLoading(false)
        })
    }

    const toGoldCoinCenter = () => {
        if(window.__wxjs_environment === 'miniprogram') {
            window.wx.miniProgram.navigateTo({
                url: '/pages/gold-coin/gold-coin'
            })
        }
    }

    const onClickReportButton = () => {
        setReportVisible(true)
    }

    const onReportSheetAction = (action) => {
        let reasonDesc = action?.text
        requestHttpHintWhenFail('/circle/api/reportBottle', {
            bottleId: currentSalvageInfo.userBottleRecord.id,
            reasonDesc: reasonDesc
        }, 'POST').then(res => {
            setReportVisible(false)
            Toast.show({content: '举报成功，我们将在三个工作日内审核并反馈结果，请耐心等待', duration: 2000})
        })
    }

    const onReportSheetClose = () => {
        setReportVisible(false)
    }

    return (
        <div ref={pageRef} className={styles.bottlePage} style={{height: `${pageHeight}px`}}>
            <Image className={styles.bottleBgImage} height={pageHeight} src={bgTheme === 'dark' ? bottleBlackBg : bottleLightBg}/>
            <div className={styles.topLine}>
                <div className={styles.filterArea} onClick={() => setFilterVisible(true)}>
                    <Image className={styles.filterIcon} src={filterIcon}/>
                    <div className={styles.filterText}>筛选</div>
                </div>
                <div className={styles.myBottleButton} onClick={toMyBottlePage}>
                    <div>我的纸条</div>
                    <Image className={styles.arrowRightIcon} src={arrowRightIcon}/>
                </div>
            </div>
            <div className={`${styles.goldCoinArea} ${bgTheme === 'dark' ? styles.goldCoinAreaDark : ''}`} onClick={toGoldCoinCenter}>
                <Image className={styles.goldCoinIcon} src={goldCoinIcon}/>
                <div className={styles.goldCoinNumber}>{userInfo?.totalCoin}</div>
            </div>
            {bgTheme !== 'dark' && <>
                    <Image className={styles.boatIcon} src={boatIcon}/>
                    <Image className={styles.balloonIcon} src={balloonIcon}/>
                </>
            }
            <div className={styles.operateArea}/>
            <div className={styles.operateButtonLine}>
                <div className={styles.operateButton}>
                    <div className={`${styles.buttonImage} ${bgTheme === 'dark' ? styles.buttonImageDark : ''}`} onClick={throwBottle}>
                        <Image className={styles.buttonImageIcon} src={bottleIcon}/>
                    </div>
                    <div className={styles.buttonText}>扔一个</div>
                    <div className={`${styles.consumeDesc} ${bgTheme === 'dark' ? styles.darkConsumeDesc : ''}`}>
                        <Image className={styles.consumeCoinIcon} src={goldCoinIcon}/>
                        <div>2</div>
                    </div>
                </div>
                <div className={`${styles.operateButton} ${styles.salvageButton}`} onClick={salvageBottle}>
                    <div className={`${styles.buttonImage} ${bgTheme === 'dark' ? styles.buttonImageDark : ''}`}>
                        <Image className={styles.buttonImageIcon} src={netIcon}/>
                    </div>
                    <div className={styles.buttonText}>捞一个</div>
                    <div className={`${styles.consumeDesc} ${bgTheme === 'dark' ? styles.darkConsumeDesc : ''}`}>
                        <Image className={styles.consumeCoinIcon} src={goldCoinIcon}/>
                        <div>2</div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomHint}>
                <div>温馨提示：扔一个或捞一个需消耗2金币哦</div>
                <div>严禁发布违规信息，否则您将受到封禁处罚</div>
            </div>
            {userInfo && userInfo?.userType === 'root' && <div className={styles.frontVersion}>V1.0.0</div>}
            <Image className={styles.lampIconPreLoad} src={lampIcon}/>
            <Mask visible={maskVisible} opacity='thin'>
                <div className={styles.lampIconWrapper}>
                    <Image className={styles.lampIcon} src={lampIcon}/>
                    <div className={styles.maskNetIconWrapper}>
                        <div className={styles.maskNetIcon}>
                            <Image src={netIcon}/>
                            <div className={styles.maskBottleWrapper}>
                                <Image className={styles.maskBottle} src={bottleIcon}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Mask>
            <Mask visible={filterVisible} onMaskClick={() => setFilterVisible(false)}>
                <div className={`${styles.filterPanel}`}>
                    <div className={styles.title}>筛选设置</div>
                    <div className={styles.divider}/>
                    <div className={styles.filterCell}>
                        <div className={styles.cellTitle}>性别</div>
                        <div className={styles.genderItemList}>
                            <div className={`${styles.genderItem} ${styles.leftGenderItem} ${tmpGender === -1 ? styles.genderItemActive : ''}`}
                                onClick={() => setTmpGender(-1)}>
                                不限
                            </div>
                            <div className={`${styles.genderItem} ${styles.middleGenderItem} ${tmpGender === 1 ? styles.genderItemActive : ''}`}
                                onClick={() => setTmpGender(1)}>
                                男
                            </div>
                            <div className={`${styles.genderItem} ${styles.rightGenderItem} ${tmpGender === 0 ? styles.genderItemActive : ''}`}
                                onClick={() => setTmpGender(0)}>
                                女
                            </div>
                        </div>
                    </div>
                    <div className={styles.divider}/>
                    {/* <div className={styles.filterCell}>
                        <div className={styles.cellTitle}>城市</div>
                        <div className={styles.genderItemList}>
                            不限城市
                        </div>
                    </div> */}
                    <div className={styles.buttonItemList}>
                        <Button className={styles.buttonItem} onClick={cancelFilterPanel}>取消</Button>
                        <Button className={`${styles.buttonItem} ${styles.confirmButton}`} onClick={confirmFilterPanel} loading={filterBtnLoading}>确定</Button>
                    </div>
                </div>
            </Mask>
            <InputAreaPopup visible={inputVisible} value={inputValue} cfmBtnLoading={cfmBtnLoading} title="扔纸条" confirmButtonName='发送' 
                onValueChange={setInputValue} onMaskClick={onInputMaskClick} onClickConfirmButton={onClickInputConfirmButton} onClickCancelButton={onClickInputCancelButton}/>
            <BottleShowPopup visible={bottleShowVisible} 
                replyValue={replyValue}
                userInfo={salvageInfo.userInfo}
                bottleRecord={salvageInfo.userBottleRecord}
                replyButtonLoading={replyButtonLoading}
                onMaskClick={onClickBottleCancelButton}
                onClickCancelButton={onClickBottleCancelButton}
                onClickReplyButton={onClickBottleReplyButton}
                onClickReportButton={onClickReportButton}
                onReplyValueChange={setReplyValue}/>
            <PopupActionSheet visible={reportVisible} actions={actions} onAction={onReportSheetAction} onClose={onReportSheetClose} inputTitle='请输入原因'/>
        </div>
    )
}
