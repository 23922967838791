import styles from './index.module.less';
import { useEffect } from 'react';

export default function IcpBeian() {

    const getBeianDescByDomain = () => {
        const hostname = window.location.hostname
        if(hostname.indexOf('jeysin.top') >= 0) {
            return "浙ICP备2022033703号";
        } else if(hostname.indexOf('onewish.cn') >= 0) {
            return "苏ICP备2023030095号-1";
        } else if(hostname.indexOf('wittyai.cn') >= 0) {
            return "苏ICP备2024070402号-1";
        } else {
            return "浙ICP备2022033703号"
        }
    }

    const getTitleByDomain = () => {
        const hostname = window.location.hostname
        if(hostname.indexOf('jeysin.top') >= 0) {
            return "杭州燕景网络科技有限公司";
        } else if(hostname.indexOf('onewish.cn') >= 0) {
            return "南京瑜亮网络科技有限公司";
        } else if(hostname.indexOf('wittyai.cn') >= 0) {
            return "南京网景微讯网络科技有限公司";
        } else {
            return "杭州燕景网络科技有限公司"
        }
    }

    useEffect(() => {
        document.title = getTitleByDomain();
    }, []);

    const handleClick = () => {
        alert("正在开发中，敬请期待")
    }

    return (
        <div>
            <div className={styles.title}>
                <h1>{getTitleByDomain()}技术文章</h1>
                <div>面向信仰编程</div>
            </div>
            <div className={styles.content}>
                <div className={styles.contentItem}>
                    <div onClick={handleClick}>领域驱动设计漫谈</div>
                </div>
                <div className={styles.contentItem}>
                    <div onClick={handleClick}>
                        Mybatis3 Dynamic Sql实践
                    </div>
                </div>
                <div className={styles.contentItem}>
                    <div onClick={handleClick}>
                            用Python实现一个爬虫爬取ZINC网站进行生物信息学数据分析
                        </div>
                </div>
                <div className={styles.contentItem}>
                    <div onClick={handleClick}>
                            Java 8新特性stream API用法总结
                        </div>
                </div>
                <div className={styles.contentItem}>
                    <div onClick={handleClick}>
                            自己动手实现一个轻量级的RPC框架
                        </div>
                </div>
            </div>
            <div className={styles.beian}>
                <a href="https://beian.miit.gov.cn">{getBeianDescByDomain()}</a>
            </div>
        </div>
    )
}