import styles from './index.module.less';
import {Image} from 'antd-mobile';

/**
 * @param {*} icons 
 * {
 *      image: null,
 *      activeImage: null,
 *      name: ''
 * }
 * @param {*} onClickIcon 
 */
export default function BottleTabbar({activeIndex = 0, icons = [], onClickIcon = () => {}}) {
    return <div className={styles.bottleTabbar}>
        {icons.map((icon, index) => <div key={icon.name} className={styles.bottleTabbarIcon} onClick={() => onClickIcon(index)}>
            <Image className={styles.bottleTabbarIconImage} src={activeIndex === index ? icon.activeImage : icon.image}/>
            <div className={`${styles.bottleTabbarIconName} ${activeIndex === index ? styles.bottleTabbarIconActive : ''}`}>{icon.name}</div>
        </div>)}
    </div>
}
